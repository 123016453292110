import React, { useState, useEffect, useCallback } from "react";
import { useAjera } from "../lib/ajera";
import { useToast, useDisclosure } from '@chakra-ui/react'
import _ from 'lodash';

import { Fab, Fabs, AutoCompleteCreateNew } from "../components";
import New from "./New";
import { TimeoutPromise } from "../lib"
import EmailMessage from "../components/EmailMessageModal";


function NewPRF({ edit, state, updateState, navigate }) {

  const [prf, setPRF] = useState({});
  const [PhaseKey, setPhaseKey] = useState(state?.PhaseKey);  // Unused if edit is false
  const [PRF_ID, setPRF_ID] = useState(prf?.ID);  // Unused if edit is false
  const [isLoading, setIsLoading] = useState(Boolean(PhaseKey) && !PRF_ID);
  console.log("NewPRF", {edit, PhaseKey, PRF_ID, isLoading}); 

  const [prfData, setPrfData] = useState({});  // Unused if edit is false

  const ajera = useAjera();
  const cachedAjera = useAjera({ cached: true });
  const toast = useToast();

  const { isOpen: emailMessageIsOpen, onOpen: emailMessageOnOpen, onClose: emailMessageOnClose } = useDisclosure();
  const [emailMessage, setEmailMessage] = useState();
  const emailMessageCallback = useCallback(handleCreateNewPRF, [ajera, PhaseKey, prf]);

  const handleSearch = async ({ key, id }) => {

    // Check that only one input is provided
    if ((Boolean(key) && Boolean(id)) || (!Boolean(key) && !Boolean(id))) {
      throw new Error("Must provide exactly one input, either key or id");
    }

    setIsLoading(true);
    
    const getPrf = async () => {

      const response = key
        ? await ajera.getProposal(parseInt(key))
        : await ajera.getProposalByID(parseInt(id));

      if (!response || !response?.Content) {
        throw new Error("Unable to retrieve proposal");
      }

      return response?.Content;
    };

    try {
      
      const proposal = await TimeoutPromise(getPrf());

      console.log(proposal);
      setPhaseKey(proposal.PhaseKey);
      setPRF_ID(proposal.ID);
      setPRF(JSON.parse(JSON.stringify(proposal)));
      setPrfData(JSON.parse(JSON.stringify(proposal)));

    } catch (error) {

      console.error(error);
      setPhaseKey(null);
      setPRF_ID("");
      setPRF({});

    } finally {

      setIsLoading(false);

    }
  };

  useEffect(() => {
    if (edit) {
      handleSearch({ key: PhaseKey });
    } else {
      setIsLoading(false);
      setPhaseKey(null);
      setPRF_ID("");
      setPRF({});
    }
  }, [PhaseKey]);


  function handleCancel() {
    navigate('view', { state: { PhaseKey }});
  }

  async function handleSave() {
    if (edit) {
      handleEditPRF();
    } else {
      emailMessageOnOpen();
      // handleCreateNewPRF();
    }
  }

  async function _handleEditPRF() {

    console.log("Saving new PRF", prf)

    setIsLoading(true)

    await TimeoutPromise(ajera.update000(PhaseKey, prf))
      .then(resp => {
        console.log("handleEditPRF", {resp})

        toast({
          title: "Successfully Updated PRF!!",
          status: "success",
          isClosable: true
        })

        updateState({ PhaseKey: resp?.Content?.Proposal?.PhaseKey });
        navigate("view");
        setIsLoading(false)

      })
      .catch(err => {
        setIsLoading(false);
      });
  }
  const handleEditPRF = useCallback(_handleEditPRF, [ajera, PhaseKey, prf]);

  async function handleCreateNewPRF() {
    console.log("Saving new PRF", prf)

    setIsLoading(true)

    await TimeoutPromise(ajera.createPrf(prf, emailMessage))
      .then(resp => {

        toast({
          title: "Successfully Submitted PRF!!",
          status: "success",
          isClosable: true
        })

        updateState({ PhaseKey: resp?.Content?.Proposal?.PhaseKey });
        navigate("view");
        setIsLoading(false)

      })
      .catch(err => {
        setIsLoading(false);
      });
  }


  const form = [

    // Title
    [
      { type: "spacer" },
      { type: "hr" },
      { label: "PROPOSAL REQUEST FORM (PRF)", type: "label" },
      { type: "hr" },
      { type: "spacer" },
    ],

    // Proposal Information
    [
      {
        label: edit ? "Description (include ID)" : "Description (ID will be Generated)",
        field: "Description",
        InputProps: {autoComplete: "off"}
      },
      {
        type: "date",
        label: "Due Date",
        // default: new Date().toISOString().slice(0, 10),
        field: "CF_PRFDueDate"
      },
      {
        type: "checkbox", 
        label: "Fixed Due Date",
        field: "CF_PRFIsFixedDueDate"
      },
      {
        label: "Estimated Amount",
        type: "dollar",
        hint: "(Make Your Best Guess)",
        cols: 12,
        field: "CF_PRFEstAmtindollars",
        parse: parseFloat
      },
      {
        label: "Is the Project Funded",
        type: "checkbox",
        field: "CF_IsFunded"
      },
      {
        label: "Contains Substantial Equipment Sales",
        type: "checkbox",
        field: "CF_PRFSubstantialEquipment"
      },
      {
        type: "select",
        label: "Industry",
        isAjeraSelect: true,
        AllowEdits: false,
        options: [ "Mining", "Oil & Gas", "Energy", "Food", "Beverage", "Misc" ],
        // options:  [ "Mining", "Oil & Gas", "Energy", "Food", "Power", "Misc" ],
        // options: async () => await ajera.listIndustry(), // TODO
        field: "CF_Industry",
        prffield: ["CF_Industry", "Value"]
      },
      // {
      //   label: "PSE Experience",
      //   cols: 12,
      //   type: "select",
      //   isAjeraSelect: true,
      //   AllowEdits: false,
      //   options: [ "Considerable", "Moderate", "Little" ],
      //   field: "CF_PRFPSEExperiance",
      //   prffield: ["CF_PRFPSEExperiance", "Value"]
      //   // onChange: (e) => console.log("PSE Experience", e)
      // },
      {
        label: "Project Type",
        cols: 12,
        type: "select",
        options: ["Project", "Placement", "Panels", "Equipment"],
        // options: () => https://localhost/method/?Method=ListProjectTypes
        field: "CF_PRFProjectType"
      },
      {
        label: "Billing Type (Contract)",
        cols: 12,
        type: "select",
        isAjeraSelect: true,
        AllowEdits: false,
        options: ["T&M", "T&M NTE", "Fixed Price", "Other"],
        field: "CF_PRFBillingType",
        prffield: ["CF_PRFBillingType", "Value"]
      },
      {
        label: "Engineering Level",
        cols: 12,
        type: "select",
        isAjeraSelect: true,
        AllowEdits: true,
        options: [ "Pre-Feasibility", "Feasibility", "Preliminary", "Detailed" ],
        field: "CF_PRFEngineeringLevel",
        prffield: ["CF_PRFEngineeringLevel", "Value"]
      },
    ],

    // Managers
    [
      { type: "spacer" },
      { type: "hr" },
      { label: "Managers", type: "label" },
      { type: "hr" },
      { type: "spacer" },
      {
        type: "select",
        label: "Project Manager",
        options: async () => (await cachedAjera.getProjectManagers()).map(x => ({ value: x.EmployeeKey, text: `${x.FirstName} ${x.LastName}`})),
        field: ["ProjectManager", "EmployeeKey"]
      },
      {
        type: "select",
        label: "Principal In Charge",
        options: async () => (await cachedAjera.getPrincipals()).map(x => ({ value: x.EmployeeKey, text: `${x.FirstName} ${x.LastName}`})),
        field: ["PrincipalInCharge", "EmployeeKey"]
      },
      {
        type: "select",
        label: "Salesperson (MarketingContact)",
        options: async () => (await cachedAjera.getMarketingContacts()).map(x => ({ value: x.EmployeeKey, text: `${x.FirstName} ${x.LastName}`})),
        field: ["MarketingContact", "EmployeeKey"]
      },
      // {
      //   type: "number",
      //   label: "Sales Generator",
      //   field: "CF_SalesGenerator1",
      //   parse: (x) => parseInt(x)
      // },
    ],

    // Client
    [
      { type: "spacer" },
      { type: "hr" },
      { label: "CLIENT INFORMATION", type: "label" },
      // TODO, create the Contact, maybe through the API or the Creation of the PRF, then get the Key and add to the selected Client.
      // TODO: If the client doesn't exist, create it. Ask for: Description, ClientTypeKey, Website, Email, PrimaryAddressLineOnePrimaryAddressCity: "", PrimaryAddressZip: "", PrimaryAddressState: "", PrimaryAddressCountry,  Notes, Contacts.[0].FirstName: "Adrian", Contacts.[0].MiddleName: "", Contacts.[0].LastName: "Dalton", Contacts.[0].Title, Contacts.[0].ContactKey: -1
      { type: "hr" },
      { type: "spacer" },

      // TODO: Contact Must be created first
      // { label: null, type: "label", field: ["Contacts", 0, "ContactKey"], value: 0 },
      // { label: null, type: "label", field: ["Contacts", 0, "Order"], value: 1 },
      // { label: "Company", field: ["Contacts", 0, "Title"] },
      // { label: "First Name", field: ["Contacts", 0, "FirstName"] },
      // { label: "Last Name", field: ["Contacts", 0, "LastName"] },
      // { label: "Description", field: ["Contacts", 0, "Description"] },
      // { type: "spacer" },
      // { type: "spacer" },
      // {
      //   label: "Notes (Phone, Email)",
      //   type: 'textArea',
      //   minRows: 4,
      //   field: ["Contacts", 0, "Text"]
      // },

      // {
      //   label: "CLIENT CREDIT RANKING",
      //   type: "range",
      //   min: 1,
      //   max: 5,
      //   default: 1,
      //   field: "CF_PRFClientCreditRanking"
      // },
      {
        type: "render",
        render: () => (
          <AutoCompleteCreateNew
            name="Client"
            title="Create Client"
            // subtitle=""
            tooltip="Create a new Client"
            placeholder="Select a Client"
            loadingText="Loading Clients"
            fields={[
              { field: "Description" },
              { field: "First Name" },
              { field: "Last Name" },
              { field: "Email" },
              { label: "Phone Number", field: "PrimaryPhoneNumber" },
              { label: "Address Line 1", field: "PrimaryAddressLineOne" },
              { label: "Address Line 2", field: "PrimaryAddressLineTwo" },
              { label: "City", field: "PrimaryAddressCity" },
              { label: "Zip", field: "PrimaryAddressZip" },
              { label: "State", field: "PrimaryAddressState" },
              { label: "Country", field: "PrimaryAddressCountry" },
              { field: "Website" },
              { label: "Notes (any other info)", field: "Notes" },
            ]}
            acGetOptionLabel={(option) => option.Description || ""}
            acGroupBy={(option) => option.Description.charAt(0).toUpperCase()}
            fetchOptions={async () => (await cachedAjera.listClients())
                        .sort((a, b) => a.Description.localeCompare(b.Description))}
            create={async (form) => (await ajera.createClients([form]))?.[0]}
            // TODO: Saving Client. Somewhere.
            onCreated={(created) => _.set(prf, ["InvoiceGroups", 0, "Client", "ClientKey"], created.ClientKey)}
            onChange={(value) => _.set(prf, ["InvoiceGroups", 0, "Client", "ClientKey"], value.ClientKey)}
          />
        )
      },
      {
        type: "render",
        render: () => (
          <AutoCompleteCreateNew
            name="Contact"
            title="Create Contact"
            tooltip="Create a new Contact"
            placeholder="Select a Contact"
            loadingText="Loading Contacts"
            fields={[
              { field: "Company" },
              { field: "Title" },
              { label: "First Name", field: "FirstName" },
              { label: "Last Name" , field: "LastName" },
              { field: "Email" },
              { label: "Phone Number", field: "PrimaryPhoneNumber" },
              { label: "Notes (any other info)", field: "Notes" },
            ]}
            acGetOptionLabel={(option) => {
              if (option.LastName && option.FirstName) {
                return option.LastName + ", " + option.FirstName;
              } else if (option.LastName) {
                return option.LastName
              } else if (option.FirstName) {
                return option.FirstName
              } else if (option.Email) {
                return "Email: " + option.Email
              } else if (option.Company) {
                return "Company: " + option.Company
              } else if (option.Title) {
                return "Title: " + option.Title
              } else {
                return "NA"
              }
            }}
            acGroupBy={(option) => option.LastName.charAt(0).toUpperCase()}
            fetchOptions={async () => (await cachedAjera.listContacts())
                .sort((a, b) => a.LastName.localeCompare(b.LastName))}
            create={async (form) => (await ajera.createContacts([form]))?.[0]}
            onCreated={(created) => {
              _.set(prf, ["Contacts", 0, "ContactKey"], created.ContactKey);
              _.set(prf, ["Contacts", 0, "Order"], 1);
            }}
            onChange={(value) => {
              _.set(prf, ["Contacts", 0, "ContactKey"], value.ContactKey);
              _.set(prf, ["Contacts", 0, "Order"], 1);
            }}
            
          />
        )
      },
      {
        label: "CLIENT CREDIT RANKING",
        type: "select",
        // default: "1 = Low Risk",
        options: [ "1 = Low Risk", "2", "3 - Medium Risk", "4", "5 - High Risk" ],
        isAjeraSelect: true,
        AllowEdits: false,
        field: "CF_PRFClientCreditRanking",
        prffield: ["CF_PRFClientCreditRanking", "Value"]
      },
      {
        type: "hint",
        value:
          "Please rank the client based on our credit policy (item 15 of PSE's General Policies and our Proposal Procedure)."
      },
    ],

    // Notes
    [
      { type: "spacer" },
      { label: "Notes", type: "label" },
      { type: "hr" },
      { type: "spacer" },
      {
        type: "textArea",
        minRows: 12,
        field: "Notes"
      }
    ],
    [
      { type: "hint", value: "default 'RateTable': 58", field: "RateTableKey", default: 58},
      { type: "hint", value: "default Over All 'BillingType': Marketing", field: "BillingType", default: "Marketing"}
    ] 
  ];

  return (
    <>
      <New obj={prf} setObj={setPRF} objOG={prfData} form={form} isLoading={isLoading} showHelperText={edit} />

      {!isLoading && <Fabs>
        <Fab onClick={handleSave} muiIcon="Save" tooltip="Submit PRF" />
        <Fab onClick={handleCancel} muiIcon="Close" tooltip="Cancel" />
      </Fabs>}

      <EmailMessage
        isOpen={emailMessageIsOpen}
        onClose={emailMessageOnClose}
        text={emailMessage}
        setText={(v) => {
          console.log(v);
          setEmailMessage(v);
        }}
        onSubmit={emailMessageCallback} />

    </>
  );
}

export default NewPRF;
