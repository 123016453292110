import React, { useEffect, useState, useCallback } from "react";
import { createUseStyles } from 'react-jss';

import { useAjera } from "../lib/ajera";
import { useToast } from "@chakra-ui/react";
import { TimeoutPromise } from "../lib";

import { CircularProgress, Tooltip, Typography } from "@mui/material";
import { Fab, Fabs } from "../components/Fab";


const StageColors = {
  "PRF": "#E74C3C", // (darker red)
  "ApprovedPRF": "#3498DB", // (blue)
  "Proposal": "#E74C3C", // (darker red, same as PRF)
  "ProposalApproved": "#3498DB", // (blue, same as ApprovedPRF)
  "ProposalSubmitted": "#919191", // (light gray)
  "Awarded": "#27AE60", // (darker green)
  "Lost": "#BDC3C7", // (darker green)
  "Active": "#BDC3C7", // (light gray)
  "InActive": "#BDC3C7", // (light gray)
}

const StageOrder = {
  "PRF": 0,
  "ApprovedPRF": 1,
  "Proposal": 2,
  "ProposalApproved": 3,
  "ProposalSubmitted": 4,
  "Awarded": 5,
  "Lost": 6,
  "Active": 7,
  "InActive": 8 
}

const useStyles = createUseStyles(({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: "0px"
  },
  loading: {
    width: "100%",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    flex: "0 0 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: "bold",
    color: "#777777",
    padding: "12px 0px 12px 16px",
    minHeight: "32px",
    // boxShadow: "1px 1px 2px #bbbbbb",
    // borderRadius: "8px",
    borderBottom: "1px solid #F0F0F0",
    borderLeft: "3px solid #0F6CBD",
    "&:hover": {
      cursor: "pointer",
      fontWeight: "bold",
      color: "#000000",
      backgroundColor: "#e7f1fb"
      // boxShadow: [
      //   "inset 1px 0 0 #dadce0",
      //   "inset -1px 0 0 #dadce0",
      //   "0 1px 2px 0 rgb(60 64 67 / 30%)",
      //   "0 1px 3px 1px rgb(60 64 67 / 15%)",
      // ],
    },
    "&:active": {
      // boxShadow: "1px 1px 2px #ffffff",
    },
  },
  subitem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    width: '100%'
  },
  description: {
    flexShrink: 1,
    
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  badge: {
    flexShrink: 0,
    
    padding: '2px 8px',
    borderRadius: '9999px',
    overflowX: 'hidden',
    fontSize: 12
  }
}));

export default function ListProposals({ state, updateState, navigate }) {
  const toast = useToast();
  const classes = useStyles();
  const ajera = useAjera();
  const cachedAjera = useAjera({ cached: true });

  const [isLoading, setIsLoading] = useState(true);


  function resetColors() {
    // Remove style variables used for Coloring Stage on hover
    const colorRX = /^--color-\d+$/;
    const bgcolorRX = /^--background-color-\d+$/;
    const style = document.documentElement.style;

    Array.from(style)
      .filter(p => p.startsWith('--'))
      .filter(p => colorRX.test(p) || bgcolorRX.test(p))
      .map(p => style.removeProperty(p));
  }

  function sortByID(a, b) {
    if (a.ID === "" && b.ID === "") {
      return 0;
    } else if (a.ID !== "" && b.ID === "") {
      return -1;
    } else if (a.ID === "" && b.ID !== "") {
      return 1;
    } else {
      return parseFloat(b.ID) - parseFloat(a.ID) // Largest number first
    }
  }

  function sortByStage(a, b) {
    if (a.CF_Stage === "" && b.CF_Stage === "") {
      return 0;
    } else if (a.CF_Stage !== "" && b.CF_Stage === "") {
      return -1;
    } else if (a.CF_Stage === "" && b.CF_Stage !== "") {
      return 1;
    } else {
      return StageOrder[a.CF_Stage] - StageOrder[b.CF_Stage]; // Lower Order number first
    }
  }

  const [sortBy, setSortBy] = useState('ID'); // 'ID', 'Stage'  
  const sortCallback = useCallback((a, b) => {
    resetColors();

    if (sortBy === 'ID') {
      return sortByID(a, b);
    } else if (sortBy === 'Stage') {
      return sortByStage(a, b);
    } else {
      return sortByID(a, b);
    }
    
  }, [sortBy]);

  function getProposals() {

    resetColors();
      
    setIsLoading(true);

    TimeoutPromise(ajera.listProposals())
      .then((x) => {
        console.log({x})
        state.proposals = x?.Content || [];

        if (!x || x?.Content?.length === 0) {
          throw new Error("No Proposals were returned")
        }

        toast({
          title: "Successfully loaded Proposals!",
          status: "success",
          isClosable: true
        })

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error: ", err);

        toast({
          title: err.message,
          status: "error",
          isClosable: true
        })

        setIsLoading(false);
      });
  }

  useEffect(() => {
    getProposals();
  }, []);

  
  const Loading = () => (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );

  const List = () => {
    return (
      <div className={classes.container}>
        {state.proposals
          .sort(sortCallback)
          .map((prop, i) => {
            return (
              <Tooltip key={i} title={prop.Description} placement="bottom" disableInteractive>
                <span
                  className={classes.item}
                  onClick={() => {
                    navigate("view", { state: { PhaseKey: prop.PhaseKey } });
                  }}
                  onMouseEnter={() => {
                    document.documentElement.style.setProperty(`--color-${i}`, 'white');
                    document.documentElement.style.setProperty(`--background-color-${i}`, StageColors[prop.CF_Stage]);
                  }}
                  onMouseLeave={() => {
                    document.documentElement.style.setProperty(`--color-${i}`, StageColors[prop.CF_Stage]);
                    document.documentElement.style.setProperty(`--background-color-${i}`, 'inherit');
                  }}
                >
                  <span
                    className={classes.subitem}
                    alt={prop.Description}
                  >
                    <span className={classes.description}>{prop.Description}</span>
                    {prop.CF_Stage && <span className={classes.badge} style={{ color: `var(--color-${i}, ${StageColors[prop.CF_Stage]})`, backgroundColor: `var(--background-color-${i}, none)` }}>
                      {prop.CF_Stage}
                    </span>}
                  </span>
                </span>
              </Tooltip>
            )
          })}
      </div>
    );
  }


  if (isLoading) {
    return <Loading />;
  }  

  if (state.proposals.length > 0) {

    return (
      <>
        <List />
        
        <Fabs>
          <Fab
            tooltip="Create a new PRF"
            muiIcon="AddCircle"
            onClick={() => navigate("prf")} />

          <Fab
            tooltip={sortBy === 'ID' ? "Sort by Stage" : "Sort by ID"}
            muiIcon={sortBy === 'ID' ? "ExpandLess" : "ExpandMore"}
            onClick={() => setSortBy(sortBy === 'ID' ? 'Stage' : 'ID')} />

          <Fab
            tooltip="Refresh this Page"
            muiIcon="Refresh"
            onClick={getProposals} />

          {/* <Fab
            tooltip="Run Dev Function"
            muiIcon=""
            onClick={devFunctionProposals} />             */}

          {/* <Fab
            tooltip="Convert PRF to Proposal"
            muiIcon="DocumentScannerRounded"
            onClick={() => navigate("proposal")} /> */}

        </Fabs>
      </>
    );
  }

  return (
    <>
      <Typography>Unable to Load Projects</Typography>
      
      <Fabs>

        <Fab
          tooltip="Refresh this Page"
          muiIcon="Refresh"
          onClick={getProposals} />
          
      </Fabs>
    </>
  );

}
