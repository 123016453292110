import React, { useState } from "react";
import PropTypes from "prop-types";

import { Fab as MuiFab, Stack, Tooltip, Typography, Popover } from "@mui/material";
import * as muiIcons from "@mui/icons-material";
import * as biIcons from "react-icons/bi";


const FAB_STYLES = {
  bgcolor: "#357EC7",
  color: "white",
  "&:hover": {
    bgcolor: "#265c92",
  },
};

function Fab({ label, tooltip, onClick, muiIcon, biIcon, variant, size, sx={} }) {
  const Icon = muiIcon ? muiIcons[muiIcon] : biIcon ? biIcons[biIcon] : undefined;

  return (
    <Tooltip title={tooltip} placement="left">
      <MuiFab
        onClick={onClick}
        variant={variant || "extended"}
        size={size || "large"}
        sx={{ ...FAB_STYLES, borderRadius: '9999px', padding: label ? '0px 16px' : '0px', ...sx }}
      >
        {Icon  && <Icon fontSize="medium" />}
        {label && <Typography fontWeight="bold" sx={{ ml: 1 }}>{label}</Typography>}
      </MuiFab>
    </Tooltip>
  );
}

Fab.propTypes = {
  onClick: PropTypes.func.isRequired,

  label: PropTypes.string,
  tooltip: PropTypes.string,
  muiIcon: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  sx: PropTypes.object,
};


const FABS_STYLES = {
  position: "fixed",
  bottom: "24px",
  right: "24px"
};

function Fabs({ children }) {
  return (
    <Stack spacing={1} sx={FABS_STYLES}>
      {children}
    </Stack>
  )  
}


// function Fabs({ children }) {
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? "simple-popover" : undefined;

//   return (
//     <>
//       <Fab onClick={handleClick} muiIcon="Add" tooltip="Add" />
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "left",
//         }}
//         transformOrigin={{
//           vertical: "top",
//           horizontal: "left",
//         }}
//       >
//         <Stack spacing={1} sx={FABS_STYLES}>
//           {children}
//         </Stack>
//       </Popover>
//     </>
//   );
// }

Fabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Fab;
export { Fab, Fabs };
