import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useAjera } from "../lib/ajera";

import { Fab, Fabs, Loading, SearchBar, FormItem } from "../components";
import { Box, Button, IconButton, Modal, TextField, Typography, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, FormHelperText } from "@mui/material";

import ViewHTML from "../components/ViewHTML";
import { TimeoutPromise } from "../lib";
import EmailMessage from "../components/EmailMessageModal";


function CenteredText({ children }) {
  const styles = {
    width: "100%",
    height: "50vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "24px 0px",

    rowGap: 24,
    color: "#357EC7",
    "& p": {
      fontSize: "1.5rem",
    }
  }

  return <Typography sx={styles}>{children}</Typography>;
}

export default function Proposal({ state, updateState, navigate, back }) {

  const [proposalData, setProposalData] = useState({});
  const [PhaseKey, setPhaseKey] = useState(state?.PhaseKey);
  const [ProposalID, setProposalID] = useState(proposalData?.ID);
  const [isLoading, setIsLoading] = useState(Boolean(PhaseKey) && !ProposalID);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const successToast = useToast({ status: 'success', isClosable: true });
  const errorToast = useToast({ status: 'error', isClosable: true });
  const toast = useToast();
  const ajera = useAjera();
  const ajeraCached = useAjera({ cached: true });

  const { isOpen: emailMessageIsOpen, onOpen: emailMessageOnOpen, onClose: emailMessageOnClose } = useDisclosure();
  const [callbackKey, setCallbackKey] = useState();
  const [emailMessage, setEmailMessage] = useState();

  const outcomes = ['Awarded', 'Lost', 'Active', 'InActive'];
  const [outcome, setOutcome] = useState('Awarded');

  const prfApprovers = useMemo(() => ({
    [proposalData.CF_PRFApproverEmployeeID]: proposalData.CF_PRFDateTimeApproved  // proposalData.CF_PRFIsApproved
  }), [proposalData])

  const proposalApprovers = useMemo(() => ({
    [proposalData.CF_ProposalApprover1EmployeeID]: proposalData.CF_ProposalApprover1Date,
    [proposalData.CF_ProposalApprover2EmployeeID]: proposalData.CF_ProposalApprover2Date,
    [proposalData.CF_ProposalApprover3EmployeeID]: proposalData.CF_ProposalApprover3Date,
    [proposalData.CF_ProposalApprover4EmployeeID]: proposalData.CF_ProposalApprover4Date
  }), [proposalData])


  // Employees
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    ajeraCached
      .getEmployees([...Object.keys(prfApprovers), ...Object.keys(proposalApprovers)]) // Do I need to change to Marking contacts, etc.
      .then(res => setEmployees(res))
      .catch(err => toast({
        title: "Unable to get Employees",
        status: "error",
        isClosable: true
      }))
  }, []);


  async function handleSearch({ key, id }) {

    // Check that only one input is provided
    if ((Boolean(key) && Boolean(id)) || (!Boolean(key) && !Boolean(id))) {
      throw new Error("Must provide exactly one input, either key or id");
    }

    setIsLoading(true);
    
    const getProposal = async () => {

      const response = key
        ? await ajera.getProposal(parseInt(key))
        : await ajera.getProposalByID(parseInt(id));

      if (!response || !response?.Content) {
        throw new Error("Unable to retrieve proposal");
      }

      return response?.Content;
    };

    try {

      const proposal = await TimeoutPromise(getProposal());

      console.log(proposal);
      setPhaseKey(proposal.PhaseKey);
      setProposalID(proposal.ID);
      setProposalData(proposal);

    } catch (e) {

      console.error(e);
      setPhaseKey("");
      setProposalID("");
      setProposalData({});
      errorToast({ title: e.message })

    } finally {

      setIsLoading(false);

    }
  };
  useEffect(() => {
    handleSearch({ key: PhaseKey });
  }, [PhaseKey]);

  
  const renderFabs = () => {

    if (proposalData.CF_Stage === "PRF") {
  
      return (
        <Fabs>
          <Fab onClick={() => handle('Approve')} muiIcon="ThumbUp" tooltip="Approve" />
          <Fab onClick={() => handle('EditPRF')} muiIcon="Edit" tooltip="Edit" />
          {/* <Fab onClick={() => handle('Delete')} muiIcon="Delete" tooltip="Delete" /> */}
        </Fabs>
      )
  
    } else if (proposalData.CF_Stage === "ApprovedPRF") {
  
      return (
        <Fabs>
          <Fab onClick={() => handle('ConvertToProposal')} muiIcon="Repeat" tooltip="Convert to Proposal" />
          <Fab onClick={() => handle('EditPRF')} muiIcon="Edit" tooltip="Edit" />
          {/* <Fab onClick={() => handle('Delete')} muiIcon="Delete" tooltip="Delete" /> */}
        </Fabs>
      )
  
    } else if (proposalData.CF_Stage === "Proposal") {
  
      return (
        <Fabs>
          <Fab onClick={() => handle('Approve')} muiIcon="ThumbUp" tooltip="Approve" />
          <Fab onClick={() => handle('EditProposal')} muiIcon="Edit" tooltip="Edit" />
          {/* <Fab onClick={() => handle('Delete')} muiIcon="Delete" tooltip="Delete" /> */}
        </Fabs>
      )
  
    } else if (proposalData.CF_Stage === "ProposalApproved") {
  
      return (
        <Fabs>
          <Fab onClick={() => handle('Submit')} muiIcon="Check" tooltip="Submit" />
          <Fab onClick={() => handle('EditProposal')} muiIcon="Edit" tooltip="Edit" />
          {/* <Fab onClick={() => handle('Delete')} muiIcon="Delete" tooltip="Delete" /> */}
        </Fabs>
      )
  
    } else if (proposalData.CF_Stage === "ProposalSubmitted") {
  
      return (
        <Fabs>
          <Fab onClick={() => handle('Outcome')} muiIcon="CheckCircle" tooltip="Complete" />
          <Fab onClick={() => handle('EditProposal')} muiIcon="Edit" tooltip="Edit" />
          <Fab onClick={() => handle('ReSubmitProposal')} muiIcon="Replay" tooltip="Re-Submit" />
          {/* <Fab onClick={() => handle('Delete')} muiIcon="Delete" tooltip="Delete" /> */}
        </Fabs>
      )
  
    } else if (outcomes.includes(proposalData.CF_Stage)) {
  
      return (
        <Fabs>
          <Fab onClick={() => handle('Outcome')} muiIcon="Repeat" tooltip="Change outcome" />
          <Fab onClick={() => handle('EditProposal')} muiIcon="Edit" tooltip="Edit" />
          <Fab onClick={() => handle('ReSubmitProposal')} muiIcon="Replay" tooltip="Re-Submit" />
          {/* <Fab onClick={() => handle('Delete')} muiIcon="Delete" tooltip="Delete" /> */}
        </Fabs>
      )
  
    // } else {
      
    //   return (
    //     <Fabs>
    //       <Fab onClick={() => handle('Outcome')} muiIcon="Repeat" tooltip="Change outcome" />
    //       <Fab onClick={() => handle('Delete')} muiIcon="Delete" tooltip="Delete" />
    //     </Fabs>
    //   )

    }
  }

  /**
   * If it's one of these just run the method, the Email Message will be set later.
   * Otherwise, set the callbackKey so we know what method to run after the Email Prompt.
   * 
   * The Email message Prompt will run emailMessageCallback onSubmit
   */
  const handle = (action) => {
    switch(action) {
      case 'EditPRF':
        handleEditPRF();
        setEmailMessage('');
        break;
      case 'ConvertToProposal':
        handleConvertToProposal();
        setEmailMessage('');
        break;
      case 'EditProposal':
        handleEditProposal();
        setEmailMessage('');
        break;
      case 'ReSubmitProposal':
        handleResubmitProposal();
        setEmailMessage('');
        break;
      case 'Complete':
        handleComplete();
        setEmailMessage('');
        break;
      case 'Delete':
        handleDelete();
        setEmailMessage('');
        break;
      default:
        setCallbackKey(action);
        emailMessageOnOpen();
     }
  }
  const _emailMessageCallback = () => {
    switch(callbackKey) {
      case 'Approve':
        handleApprove();
        break;
      case 'Submit':
        handleSubmit();
        break;
      case 'Outcome':
        setCallbackKey(null);
        return handleOutcome();
      default:
        console.log('Unknown action:', callbackKey);
    }
    setCallbackKey(null);
    setEmailMessage('');
  }
  const emailMessageCallback = useCallback(_emailMessageCallback, [ajera, PhaseKey, proposalData, emailMessage, outcome]);

  const handleApprove = async () => {
    console.log("Approve");

    setIsLoading(true)

    const req = ["PRF", "ApprovedPRF"].includes(proposalData.CF_Stage) ?
                  ajera.approvePrf(PhaseKey, emailMessage) :
                  ajera.approveProposal(PhaseKey, emailMessage)

    TimeoutPromise(req)
      .then((resp) => {
        setProposalData(resp.Content.Proposal)
        successToast({ title: "Successfully approved Proposal: " + PhaseKey })
      })
      .catch(() => errorToast({ title: "Unable to approve Proposal: " + PhaseKey }))
      .finally(() => setIsLoading(false));

  };

  const handleEditPRF = async () => {
    console.log("Edit PRF: ", PhaseKey);
    navigate('prf-edit', { state: { PhaseKey: PhaseKey } })
  };
  
  const handleConvertToProposal = async () => {
    console.log("Convert To Proposal: ", PhaseKey);
    navigate('proposal', { state: { PhaseKey: PhaseKey } })
  };

  const handleResubmitProposal = async () => {
    console.log("Re-submit Proposal: ", PhaseKey);
    navigate('proposal-resubmit', { state: { PhaseKey: PhaseKey } })
  }

  const handleEditProposal = async () => {
    console.log("Edit Proposal: ", PhaseKey);
    navigate('proposal-edit', { state: { PhaseKey: PhaseKey } })
  };

  const handleSubmit = async () => {
    console.log("handleSubmit");

    setIsLoading(true)

    if (!["ProposalApproved"].includes(proposalData.CF_Stage)) {
      errorToast({ title: `Stage '${proposalData.CF_Stage}' not permitted to be submitted` });
      setIsLoading(false);
      return;
    }

    TimeoutPromise(ajera.submitProposal(PhaseKey, emailMessage))
      .then((resp) => {
        setProposalData(resp.Content.Proposal)
        successToast({ title: "Successfully submitted Proposal: " + PhaseKey })
      })
      .catch(() => errorToast({ title: "Unable to submit Proposal: " + PhaseKey }))
      .finally(() => setIsLoading(false));
  }

  /**
   * Opens Outcome modal, and modal submits using handleComplete
   */
  const handleOutcome = async () => {
    onOpen();
  }
  
  const handleComplete = async () => {
    console.log("handleComplete")

    setIsLoading(true)

    console.log(["ProposalSubmitted", ...outcomes], !["ProposalSubmitted", ...outcomes].includes(proposalData.CF_Stage))
    if (!["ProposalSubmitted", ...outcomes].includes(proposalData.CF_Stage)) {
      errorToast({ title: `Stage '${proposalData.CF_Stage}' not permitted to be completed` });
      setIsLoading(false);
      return;
    }

    TimeoutPromise(ajera.completeProposal(PhaseKey, outcome, emailMessage))
      .then((resp) => {
        setProposalData(resp.Content.Proposal)
        setIsLoading(false);
        successToast({ title: "Successfully completed Proposal: " + PhaseKey })
      })
      .catch(() => {
        setIsLoading(false);
        errorToast({ title: "Unable to complete Proposal: " + PhaseKey })
      })
      .finally(() => setIsLoading(false));
  }

  const handleDelete = async () => {
      
    setIsLoading(true)

    await TimeoutPromise(ajera.deleteProposal(PhaseKey))
      .then(() => {
        navigate("list", { state: { reload: true } })
        successToast({ title: "Successfully deleted Proposal: " + PhaseKey })
      })
      .catch(() => errorToast({ title: "Unable to delete Proposal: " + PhaseKey }))
      .finally(() => setIsLoading(false));
      
  }

  if (isLoading) {
    return (
      <Loading text={PhaseKey ? `Key: ${PhaseKey}` : `ID: ${ProposalID}`} />
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>

      <FormItem type="spacer" />

      <SearchBar
        callback={async () => state.proposals || (await ajera.listProposals()).Content}
        label="Proposal ID"
        value={ProposalID}
        onChange={value => {
          setPhaseKey(value.PhaseKey);
          setProposalID(value.ID);
          handleSearch({ key: value.PhaseKey });
        }}
      />
      
      {!PhaseKey ? (
        <CenteredText>
          Proposal not found with {PhaseKey ? `Key: ${PhaseKey}` : `ID: ${ProposalID}`}
        </CenteredText>
      ) : (
        <ViewHTML proposal={proposalData} employees={employees} />
      )}

      <Modal
        id={'outcome-modal'}
        open={isOpen}
        onClose={onClose}
      >

        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
        }}>

          <FormControl variant="standard" sx={{ p: 2, width: '100%' }}>
            
            <Typography variant="h6" component="h6">
              Select an Outcome
            </Typography>

            <hr style={{ margin: '16px 0px' }} />

            <RadioGroup
              defaultValue={proposalData.CF_ProposalOutcome ?? outcome}
              onChange={e => setOutcome(e.target.value)}
            >
              
              {outcomes.map(out => (
                <FormControlLabel key={out} value={out} label={out} control={<Radio />} />
              ))}

            </RadioGroup>

            <hr style={{ margin: '16px 0px' }} />
            
            <div style={{ display: 'flex' }}>
              <Button variant="contained" sx={{ mt: 2, ml: 'auto' }} onClick={() => {
                onClose();
                handle('Complete');
              }}>
                Submit
              </Button>
            </div>
            
          </FormControl>

        </Box>
      </Modal>

      {renderFabs()}

      <EmailMessage
        isOpen={emailMessageIsOpen}
        onClose={emailMessageOnClose}
        text={emailMessage}
        setText={setEmailMessage}
        onSubmit={emailMessageCallback} />

    </div>
  );
}


// {/* Wanted Fields */}
// ID
// Description
// {/* PM
// PIC
// MC */}
// Notes
// Contacts: [
//   {{Text: "", Description: "", FirstName: "", MiddleName: "", LastName: "", Title: ""}}
// ]

// {/* Custom Fields */}
// CF_Stage : Text
//    "PRF"               ->  PRF created, Email everyone     ->  Wait for Brent
//    "ApprovedPRF"       ->  Brent approves, Email everyone  ->  Paul starts to create the  Proposal

//    "Proposal"          ->  Paul Changes PRF to Proposal, Email everyone  -> Wait for Approvers
//    "ProposalApproved"  ->  Brent and 2 others Approve, Email everyone
//    "ProposalSubmitted" ->  Proposal Sent to Company, Email everyone

//    "Awarded"           ->  Paul Updates the Outcome to Awared or Inactive (Lost), Email everyone
//    "InActive"          ->  Someone Converts "Awarded" to a Project ("Active": if they want the proposal to keep showing u0p)

// CF_Industry : DONE
// CF_IsFunded : DONE
// CF_SalesGenerator : Number
// ["MarketingContact", "EmployeeKey"] i.e. Salesperson : DONE


// {/* PRF */}
// CF_PRFDueDate : Date
// CF_PRFIsFixedDueDate : Checkbox
// CF_PRFClientCreditRanking : Add PRF to the beginning
// CF_PRFEstAmtindollars : DONE
// CF_PRFSubstantialEquipment : Checkbox
// CF_PRFPSEExperiance : Custom List ["LITTLE", "MODERATE", "CONSIDERABLE"] // TODO  Spelled Wrong
// CF_PRFProjectType : Custom List [ "Placement", "Time & Expense (Not to Exceed)", "Programming", "Time & Expense", "Panel", "Fixed Fee", "Forensic", "Equipment" ]
// CF_PRFProjectBillingType -> CF_PRFBillingType
// CF_PRFEngineeringLevel : Custom List ["PRE-FEAS", "FEASIBILITY", "PRELIMINARY", "DETAILED"]

// CF_PRFIsApproved : Checkbox
// CF_PRFApproverEmployeeID : Number
// CF_PRFDateTimeApproved : Date


// {/* Proposal */}
// CF_ProposalDollarAmount : Number
// CF_ProposalProjectType : Custom List [ "Placement", "Time & Expense (Not to Exceed)", "Programming", "Time & Expense", "Panel", "Fixed Fee", "Forensic", "Equipment" ]
// CF_ProposalBillingType : Custom List [ "T&M", "T&M NTE", "Fixed Price", "Other" ]
// CF_ProposalEngineeringLevel : Custom List ["PRE-FEAS", "FEASIBILITY", "PRELIMINARY", "DETAILED"]
// CF_ProjectExpectedAwardDate : Date
// CF_ProposalPropabilityOfAward : Number
// CF_ProposalTerms : Text
// CF_ProposalOutcome : TEXT - 'Awarded/Lost/Declined' // later(prop to proj)
// EstimatedStartDate : DONE
// EstimatedCompletionDate : DONE


// CF_ProposalEstDollar_Controls : Number
// CF_ProposalEstHours_Controls : Number
// CF_ProposalEstDollar_Mechanical : Number
// CF_ProposalEstHours_Mechanical : Number
// CF_ProposalEstDollar_Electrical : Number
// CF_ProposalEstHours_Electrical : Number
// CF_ProposalEstDollar_Structural : Number
// CF_ProposalEstHours_Structural : Number
// CF_ProposalEstDollar_Total : Number
// CF_ProposalEstHours_Total : Number

// CF_ProposalIsApproved : Checkbox
// CF_ProposalApprover1EmployeeID : Number
// CF_ProposalApprover1Date : Date
// CF_ProposalApprover2EmployeeID : Number
// CF_ProposalApprover2Date : Date
// CF_ProposalApprover3EmployeeID : Number
// CF_ProposalApprover3Date : Date
// CF_ProposalApprover4EmployeeID : Number
// CF_ProposalApprover4Date : Date


// Some Current Variables I see
// CF_PRFProjectBillingType: [ T&M, T&M NTE, Fixed Price, Other ]
// CF_Industry: [ Mining, Oil & Gas, Energy, Food, Beverage, Misc ]
// CF_ClientRanking: [ 1 = Low Risk, 2, 3 - Medium Risk, 4, 5 - High Risk ]


// When creating Project
// CF_ProposalBillingType      -> BillingType
// CF_ProposalProjectType      -> ProjectType
//     [ "Placement", "Time & Expense (Not to Exceed)", "Programming", "Time & Expense", "Panel", "Fixed Fee", "Forensic", "Equipment" ]
// CF_ProposalEngineeringLevel -> CF_EngineeringLevel : Custom List ["PRE-FEAS", "FEASIBILITY", "PRELIMINARY", "DETAILED"]







{/* Employee CF for Admin Variables */}
// CF_IsPRFApprover
// CF_IsProposalApprover
// CF_IsProjectCreator


// Statuses
// The following statuses are used in the system to track the progress of a proposal:

// PRF
// Description: A new PRF has been created.
// Color: Orange
// Action: Email sent to everyone. Wait for Brent's approval.
// ApprovedPRF
// Description: The PRF has been approved by Brent.
// Color: Yellow
// Action: Email sent to everyone. Paul starts to create the proposal.
// Proposal
// Description: The proposal has been created by Paul.
// Color: Green
// Action: PRF is changed to Proposal. Email sent to everyone. Wait for approvers.
// ProposalApproved
// Description: The proposal has been approved by Brent and 2 others.
// Color: Light green
// Action: Email sent to everyone.
// ProposalSubmitted
// Description: The proposal has been submitted to the company.
// Color: Blue
// Action: Email sent to everyone.
// Awarded
// Description: The proposal has been awarded.
// Color: Purple
// Action: Outcome updated to "Awarded" or "Inactive" (if lost). Email sent to everyone.
// InActive
// Description: The proposal has been converted to a project.
// Color: Gray
// Action: Outcome updated to "Inactive". Email sent to everyone.



// Employee CFs
// CF_IsPRFCreator
// CF_IsPRFApprover
// CF_IsProposalCreator
// CF_IsProposalApprover
// CF_IsProposalSubmitter
// CF_IsProposalCompleter
// CF_IsProjectCreator