import React, { useState, useEffect } from "react";
import { TextField, Autocomplete, Typography } from "@mui/material";


function SearchBar({ callback, label, value, onChange }) {

  const [options, setOptions] = useState([]);

  useEffect(() => {

    async function getIDS() {
      const resp = await callback();
      const opts = resp?.sort((a, b) => parseFloat(b?.ID) - parseFloat(a?.ID));
      setOptions(opts);

    }

    getIDS();

  }, []);

  return (
    <Autocomplete
      // freeSolo
      defaultValue={value || ""}
      options={options}
      groupBy={(option) => option?.ID?.substring(0, 4) || "No ID"}
      onChange={(e, v, r) => onChange(v)}
      isOptionEqualToValue={(option, value) => option?.ID === value}
      getOptionLabel={(option) => option.ID ?? option}
      renderInput={(params) =>
        <TextField
          {...params}
          size="small"
          label={label || "Select an option"}
        />
      }
      renderOption={(props, option) => 
        <Typography
          {...props}
          size="small"
          fontWeight="bold"
        >
          {(option?.ID ? option?.ID + ' - ' : '') + option?.Description}
        </Typography>
      }
    />
  )
}

export default SearchBar;