import React, { useEffect, useRef, useState } from "react";
import { useToast, useDisclosure } from '@chakra-ui/react'
import { useAjera } from "../../lib/ajera";
import { DateFormatting } from "../../lib"
import _ from 'lodash';

import { Grid, Modal, Box, Button, Typography, Autocomplete, IconButton, TextField, Tooltip, CircularProgress } from "@mui/material";
import { FormItem, Fab, Fabs, Loading } from "../";
import { AddCircleOutline } from "@mui/icons-material";


const AutoCompleteCreateNewFormItem = ({ key, label, field, obj, ...props }) => (
  <FormItem
    key={key}
    type="text"
    label={label || field}
    default={_.get(obj, field, "")}
    onChange={(e) => _.set(obj, field, e.target.value)}
    {...props}
  />
)


function AutoCompleteCreateNew({ key, ...props }) {

  // States
  const keyName = `${props.name}Key`
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState({});
  const [form, setForm] = useState({});


  // Fetching Options
  const [options, setOptions] = useState([]);
  const fetchOptions = async () => {
    const options = await props.fetchOptions();
    setOptions(options);
  }
  useEffect(() => {
    fetchOptions();
  }, [])
  

  // Handlers
  const handleAdd = () => {
    console.log("handleAdd")
    onOpen();
  };

  function handleCancel() {
    setForm(prev => ({}))
    onClose()
  }

  function handleCreate() {

    _.set(form, keyName, -1)
    _.set(form, "LastModifiedDate", DateFormatting.toAjeraDateFormat())

    props
      .create(form)
      .then(created => {

        setForm(prev => ({}))
        onClose()

        console.log(created)
        props.onCreated(created)
        setSelected(prev => ({ ...prev, [keyName]: created[keyName] }))
        fetchOptions()

        toast({
          title: `Successfully Created ${props.name}!`,
          status: "success",
          isClosable: true
        })

      })
      .catch(err => {
        
        setForm(prev => ({}))
        onClose()

        toast({
          title: err.Message || `Unable to create ${props.name}`,
          status: "error",
          isClosable: true
        })

      })
  }


  // if (_.get(selected, ["ClientKey"])) {
  //   return (
  //     <Typography fontSize={18} my={"auto"}>Selected: {_.get(selected, ["Description"])}</Typography>
  //   )
  // }
  

  if (options.length === 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', columnGap: '8px' }}>
        <CircularProgress />
        <Typography fontWeight={"bold"} fontSize={18} my={"auto"}>{props.loadingText}</Typography>
      </div>
    )
  }

  return (
    <>
      <Autocomplete
        freeSolo
        key={key}
        defaultValue={selected[keyName] ? selected : null}
        isOptionEqualToValue={(option, value) => option[keyName] === value[keyName]}
        getOptionLabel={props.acGetOptionLabel}
        // groupBy={props.acGroupBy}

        options={options}
        onChange={(e, value) => {
          console.log(value)
          setSelected(prev => ({ ...prev, [keyName]: value[keyName] }))
          props.onChange(value)
        }}

        renderInput={({ key, ...params}) => (
          // <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              {...params}
              size="small"
              label={props.placeholder}
            />
              // {/* <IconButton onClick={handleAdd}>
                // <Tooltip title={props.tooltip}>
                  // <AddCircleOutline />
                // </Tooltip>
              // </IconButton> */}
          // </div>
        )}

        renderOption={({key, ...props}, option, state) => {
          return <li key={key + state?.index} {...props} style={{minHeight: '24px', fontSize:'12px'}}>{key}</li>
        }}
      />

      <Modal
        key={key + '-modal'}
        open={isOpen}
        onClose={onClose}
      >

        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>

          {props.subtitle && (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {props.subtitle}
            </Typography>
          )}

          {props.fields?.map(({ label, field }, i) => (
            <AutoCompleteCreateNewFormItem key={i} label={label} field={field} />
          ))}
          
          <Box sx={{mt: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Button onClick={handleCancel}                    >Cancel</Button>
            <Button onClick={handleCreate} variant="contained">Create</Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default AutoCompleteCreateNew;