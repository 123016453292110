import { API, CachedAPI } from "./api";

class Ajera extends API {

  // Projects
  async listProjects() {
    return await this.get("/projects");
  }

  async listProjectsByID(id) {
    return await this.get(`/projects/${id}`);
  }

  async getProject(key) {
    return await this.get(`/project?key=${key}`);
  }

  async getProjectByID(id) {
    return await this.get(`/project?id=${id}`);
  }

  async getProjectTotals(key) {
    return await this.get(`/project?key=${key}&totals=true`);
  }
  
  async getProjectTotalsByID(id) {
    return await this.get(`/project?id=${id}&totals=true`);
  }

  async createProject(templateKey, project) {
    return await this.post("/projects", { ProjectTemplateKey: templateKey, Project: project });
  }

  async updateProject(key, project) {
    return await this.put(`/projects/${key}`, { ProjectUpdates: project });
  }

  async deleteProject(key) {
    return await this.delete(`/projects/${key}`);
  }

  // Proposals
  async listProposals() {
    return await this.get("/proposals");
  }

  async getProposal(key) {
    return await this.get(`/proposal?key=${key}`);
  }

  async getProposalByID(id) {
    return await this.get(`/proposal?id=${id}`);
  }

  async create000(proposal) {
    return await this.post("/proposals", { proposal });
  }

  async update000(key, proposal) {
    return await this.put(`/proposals/${key}`, { proposal });
  }

  async delete000(key) {
    return await this.delete(`/proposals/${key}`);
  }


  // Clients
  async listClients() {
    return (await this.get("/clients", { list: true })).Content.Clients
  }
  
  async getClients(keys) {
    return (await this.get("/clients", { keys })).Content.Clients
  }
  
  async createClients(clients) {
    return (await this.post("/clients", clients)).Content.Clients
  }
  
  async updateClients(updated, current) {
    return (await this.patch("/clients", { updated, current })).Content.Clients
  }
  
  async deleteClients(clients) {
    return (await this.delete("/clients", clients)).Content.Clients
  }


  // Contacts
  async listContacts() {
    return (await this.get("/contacts", { list: true })).Content.Contacts
  }
  
  async getContacts(keys) {
    return (await this.get("/contacts", { keys })).Content.Contacts
  }
  
  async createContacts(contacts) {
    return (await this.post("/contacts", contacts)).Content.Contacts
  }
  
  async updateContacts(updated, current) {
    return (await this.patch("/contacts", { updated, current })).Content.Contacts
  }
  
  async deleteContacts(contacts) {
    return (await this.delete("/contacts", contacts)).Content.Contacts
  }

  
  // Employees
  async listEmployees() {
    return (await this.get("/employees", { list: true })).Content.Employees
  }
  
  async getEmployees(keys) {
    return (await this.get("/employees", { keys })).Content.Employees
  }

  // Ajera Role Based
  async getPrincipals() {
    return (await this.get("/employees/principals")).Content.Employees;
  }

  async getSupervisors() {
    return (await this.get("/employees/supervisors")).Content.Employees;
  }

  async getProjectManagers() {
    return (await this.get("/employees/project-managers")).Content.Employees;
  }

  async getAccountingManagers() {
    return (await this.get("/employees/accounting-managers")).Content.Employees;
  }

  async getMarketingContacts() {
    return (await this.get("/employees/marketing-contacts")).Content.Employees;
  }
  
  // Toolbox Based 
  async getPRFApprovers() {
    return (await this.get("/employees/prf-approvers")).Content.Employees;
  }
  
  async getProposalApprovers() {
    return (await this.get("/employees/proposal-approvers")).Content.Employees;
  }

  async getProjectCreators() {
    return (await this.get("/employees/project-creators")).Content.Employees;
  }
  

  // Actions
  async createPrf(prf, emailMessage, sendEmail) {
    return await this.post("/actions/prf-created", { prf, email: { message: emailMessage, send: sendEmail } });
  }
  async approvePrf(key, emailMessage, sendEmail) {
    return await this.post("/actions/prf-approved", { key, email: { message: emailMessage, send: sendEmail } });
  }
  async createProposal(key, proposal, emailMessage, sendEmail, resubmit) {
    return await this.post("/actions/proposal-created", { key, proposal, email: { message: emailMessage, send: sendEmail }, resubmit });
  }
  async approveProposal(key, emailMessage, sendEmail) {
    return await this.post("/actions/proposal-approved", { key, email: { message: emailMessage, send: sendEmail } });
  }
  async submitProposal(key, emailMessage, sendEmail) {
    return await this.post("/actions/proposal-submitted", { key, email: { message: emailMessage, send: sendEmail } });
  }
  async completeProposal(key, outcome, emailMessage, sendEmail) {
    return await this.post("/actions/proposal-completed", { key, outcome, email: { message: emailMessage, send: sendEmail } });
  }

}

class AjeraCached extends Ajera {
  constructor() {
    super();
    this.isCachedAPI = true;
    this.cached = new CachedAPI();
  }

  async get(path, payload) {
    const cachedRequest =  await this.cached.cachedRequest(path, payload, "GET");
    return cachedRequest;
  }
}

/**
 * 
 * @param {object} options
 * @param {boolean} options.cached - returns an Ajera API that cached results for default: 1-hour
 * @returns {Ajera}
 */
export const useAjera = ({ cached } = {}) => {
  if (cached) {
    return new AjeraCached();
  }
  return new Ajera()
};
