import React from "react";

import { create } from "jss";
import nested from "jss-plugin-nested";
import camelCase from "jss-plugin-camel-case";
import { jssPreset } from "@mui/styles";
import { Box } from "@mui/material";


export const blueHex = "#357EC7";
export const mediumBlueHex = "#357EC79A";
export const lightBlueHex = "#e7f1fb";

export const redHex = "#ff4646";
export const mediumRedHex = "#ff46469A";
export const lightRedHex = "#ffb3b39a";

export const greenHex = "#008000"
export const mediumGreenHex = "#0080009A"
// export const lightGreenHex = "#0080009A"

export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px"
  },
  table: {
    borderCollapse: 'collapse',
    '& th, td': {
      border: '1px solid #dddddd',
      borderCollapse: 'collapse'
    },
    '& thead': {
      backgroundColor: blueHex,
      color: 'white'
    },
    '& tbody th': {
      textAlign: 'left'
    },
    '& tbody tr td': {
      paddingTop: '2px',
      paddingBottom: '2px'
    },
    '& tbody tr td:nth-child(n+2)': {
      textAlign: 'right'
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: '#f2f2f2'
    },
    '& tbody tr:hover': {
      backgroundColor: '#dddddd'
    },
    '& td': {
      width: '50%',
      textAlign: 'left'
    }
  },
  notes: {
    paddingLeft: '2px',
    paddingRight: '2px'
  },
  hr: {
    borderTop: '5px dotted #06c',
    marginTop: '12px',
    marginBottom: '12px'
  },
  spacer: {
    marginTop: '4px',
    marginBottom: '4px'
  },
  label: {
    textAlign: 'center',
    fontSize: '16px'
  },
  inlineLabel: {
    '&:before': {
      borderTop: '5px dotted #06c',
      marginTop: '12px',
      marginBottom: '12px'
    }
  },
  descriptionContainer: {
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'space-between'
    position: 'relative'
  },
  description: {
    fontSize: "18px",
    overflow: 'hidden',
    whiteSpace: 'wrap'
  },
  badge: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    color: 'white',
    fontSize: '14px',
    // height: '100%',
    padding: '2px 8px',
    fontWeight: 'bold',
    borderRadius: '9999px', 
  },
  // dynamicTD: {
  //   '&::before': {
  //     content: '',
  //     position: 'absolute',
  //     top: 0,
  //     left: 0,
  //     width: '50%',
  //     height: '100%',
  //     // backgroundColor: 'lightblue',
  //     zIndex: -1,
  //     backgroundSize: '100% 100%',
  //   }
  // },
  // dynamicTDBlue: {
  //   '&::before': {
  //     backgroundColor: lightBlueHex,
  //   }
  // },
  // dynamicTDRed: {
  //   '&::before': {
  //     backgroundColor: lightRedHex,
  //   }
  // }
}

// for (let i = 0; i <= 100; i++) {
//   styles['dynamicTD-' + i] = {
//     '&::before': {
//       width: i + '%'
//     }
//   }
// }

const jss = create({ plugins: [...jssPreset().plugins, nested(), camelCase()] });
const styleSheet = jss.createStyleSheet(styles, { link: true });

export const sheet = styleSheet.toString();

export const classes = styleSheet.attach().classes;

console.log({styles, sheet, classes})

// Helper Components
export function Container({ children }) {
  return (
    <div className={classes.container}>
      {children}
    </div>
  )
}

export function Header({ text, children }) {
  return (
    <span className={classes.descriptionContainer}>
      <span className={classes.description}>{text}</span>
      {children}
    </span>
  )
}

export function Badge({ text, color }) {
  return (
    <span className={classes.badge} style={{ backgroundColor: color }}>
      {text}
    </span>
  )
}

export function HR() {
  return <hr className={classes.hr}></hr>
}

export function Spacer() {
  return <div className={classes.spacer}></div>
}

export function Label({ label, removeHR=false, inline=false }){

  if (inline) {
    return (
      <>
        <Spacer />

          <Box component="span" sx={{
            textAlign: 'center',
            fontSize: '16px',
            position: 'relative',
            
            '&::before': {
              content: '""',
              borderTop: '3px dotted #06c',
              position: 'absolute',
              top: '50%',
              left: '0',
              width: '35%',
              height: '0',
              marginTop: '-3px',  // Half of the border height to vertically center
            },
            '&::after': {
              content: '""',
              borderTop: '3px dotted #06c',
              position: 'absolute',
              top: '50%',
              right: '0',
              width: '35%',
              height: '0',
              marginTop: '-3px',  // Half of the border height to vertically center
            }
          }}>
            {label}
          </Box>

        <Spacer />
      </>
    )
  }

  return (
    <>
      <Spacer />
      {!removeHR && <HR />}
      <span className={classes.label}>{label}</span>
      <Spacer />
    </>
  )
}

export function Table({ children }) {
  return (
    <table className={classes.table}>
      {children}
    </table>
  )
}

export function Notes({ children }) {
  return (
    <span className={classes.notes}>
      {children}
    </span>
  )
}

export function Contacts({ contacts }) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Company</th>
          <th>Title</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {contacts?.map((contact, i) => (
          <tr key={i}>
            <td>{contact.Company}</td>
            <td>{contact.Title}</td>
            <td>{contact.FirstName} {contact.LastName}</td>
            {/* <td><TextPopup text={contact.Text}></TextPopup></td> */}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

// export function ProgressTD({ children, value, isGood }) {

//   return (
//     <td className={[
//       classes.dynamicTD,
//       classes.dynamicTD + '-' + value,
//       isGood ? classes.dynamicTDBlue : classes.dynamicTDRed
//     ]}>
//       {children}
//     </td>
//   )
// }
