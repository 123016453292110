import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Modal,
  Select,
  Switch,
  Slider,
  Button,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  InputAdornment,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

import { Alarm, AttachMoney, Percent } from "@mui/icons-material";


const MyTextField = ({ key, rightIcon, ...props }) => (
  <TextField
    key={key}
    id={props.label}
    size="small"
    color="accent"
    variant="standard"
    type={props.type}
    label={props.label}
    defaultValue={props.default}
    placeholder={props.placeholder}
    {...props}
    sx={{ width: props.width || "100%", ...props.sx }}
    onChange={(e, v, r) => {
      console.log("MyTextField: ", e, e.target, e.target.value, props.type, v, r)
      let value = e.target.value;
      if (props.type === "number") {
        value = parseFloat(value)
      }
      props.onChange(e, value)
    }}
    disabled={props.disabled}
    InputProps={{
      startAdornment: (
        props.icon
          ? <InputAdornment position="start">
              {props.icon}
            </InputAdornment>
          : undefined
      ),
      endAdornment: (
        rightIcon
          ? <InputAdornment position="end">
              {rightIcon}
            </InputAdornment>
          : undefined
      ),
      ...props.InputProps
    }}
    InputLabelProps={{ shrink: true }}
  />
);

const MyCheckBox = ({ key, ...props }) => (
  <FormControlLabel
    key={key}
    id={props.label}
    control={<Switch defaultChecked={props.default || false} size="small" color="accent" onChange={(e) => props.onChange(e, e.target.value)} />}
    sx={{ m: 1, width: props.width || "100%" }}
    label={
      <Typography variant="subtitle2" color="textSecondary">
        {props.label}
      </Typography>
    }
    // helperText={props.helperText}
  />
);

const MySlider = ({ key, ...props }) => {
  const marks = [
    { value: props.min, label: "Low Risk" },
    { value: props.max, label: "High Risk" },
  ];
  return (
    <Box sx={{ margin: "auto 48px" }}>
      <Slider
        key={key}
        color="accent"
        valueLabelDisplay="auto"
        defaultValue={props.default}
        marks={marks}
        step={1}
        min={props.min}
        max={props.max}
        size="small"
        onChange={(e) => props.onChange(e, e.target.value)}
        // helperText={props.helperText}
      />
    </Box>
  );
};

const MyDollar = ({ key, ...props }) => (
  <MyTextField
    key={key}
    {...props}
    icon={<AttachMoney />}
    type="number"
  />
);

const MyHours = ({ key, ...props }) => (
  <MyTextField
    key={key}
    {...props}
    icon={<Alarm />}
    type="number"
  />
);

const MyPercent = ({ key, ...props }) => (
  <MyTextField
    key={key}
    {...props}
    rightIcon={<Percent />}
    type="number"
  />
);

const MySelect = ({
  key,
  label,
  default: Default,
  options: Options,
  onChange,
  helperText,
  AllowEdits,
  isAjeraSelect,
  ...props
}) => {

  const [selectValue, setSelectValue] = useState(Default || "");
  const [options, setOptions] = useState([]);
  
  useEffect(() => {

    if (typeof Options === 'function') {

      const resp = Options();

      if (Promise.resolve(resp) === resp) {
        resp
          .then(value => {
            setOptions(value)
          })
          .catch(err => setOptions(null))

      } else {
        setOptions(resp)
      }

    } else {
      setOptions(Options)
    }

  }, [Options])


  if (options === null) {
    return <Typography>Unable to query options</Typography>;
  }
  
  return (
    <FormControl sx={{ display: 'flex', mt: 1 }} size="small">
      <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
      <Select
        key={key}
        labelId="demo-simple-select-helper-label"
        id={label}
        value={selectValue}
        label={label}
        {...props}
        onChange={(e) => {
          setSelectValue(e.target.value)
          onChange?.(e, e.target.value)
        }}
      >
  
        <MenuItem value={null} sx={{minHeight: '24px'}}><em>-</em></MenuItem>
        {options.map(option => {
          if (typeof option !== "object") {
            return <MenuItem key={option} value={option} sx={{minHeight: '24px'}}>{option}</MenuItem>
          } else {
            return <MenuItem key={option.value} value={option.value} sx={{minHeight: '24px'}}>{option.text}</MenuItem>
          }
        })}
  
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

const MyChoices = (props) => (
  <Grid container>
    <Grid item xs={12} key={props.label}>
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        {props.label}
      </Typography>
    </Grid>

    {props.choices.map((choice, i) => (
      <Grid item xs={6} key={i}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              color="accent"
              sx={{ p: 0, pr: 1 }}
              // TODO onChange={(event, newVal) => onChoicesChange(event, newVal, props, choice)}
              onChange={(e) => props.onChange(e, e.target.value)}
            />
          }
          sx={{ margin: "auto", width: props.width || "100%" }}
          label={
            <Typography variant="subtitle2" color="textSecondary">
              {choice}
            </Typography>
          }
        />
      </Grid>
    ))}
  </Grid>
);

// TODO: \n -> \r\n 
const MyTextArea = (props) => (
  <TextField
    id={props.label}
    multiline
    fullWidth
    size="small"
    color="accent"
    label={props.label}
    minRows={props.minRows}
    InputProps={{
      style: { fontSize: props.fontSize },
    }}
    {...props}
    onChange={(e) => props.onChange(e, e.target.value)}
  ></TextField>
);

const MyRender = (props) => {
  const RenderComponent = props.render;
  return <RenderComponent />
}

export default function FormItem(props) {
  const type = props.type ?? typeof props.value;

  switch (type) {
    case "label":
      if (props.label === null) {
        return null;
      }

      return (
        <Typography component="h5" sx={{ fontWeight: "bold", textAlign: "center" }}>
          {props.label}
        </Typography>
      );

    case "spacer":
      return <div style={{ marginTop: props.marginTop || "4px", marginBottom: props.marginBottom || "4px" }}></div>;

    case "hr":
      return <hr style={{ borderTop: "5px dotted #06c" }} />;

    case "text":
      return <MyTextField {...props} />;

    case "number":
      return <MyTextField {...props} type="number" />;

    case "date":
      return <MyTextField {...props} />;

    case "checkbox":
      return <MyCheckBox {...props} />;

    case "range":
      return <MySlider {...props} />;

    case "hint":
      return (
        <Typography variant="subtitle2" color="textSecondary">
          {props.value || props.label}
        </Typography>
      );

    case "dollar":
      return <MyDollar {...props} />;
      
    case "hours":
      return <MyHours {...props} />;
      
    case "percent":
      return <MyPercent {...props} />;
      
    case "select":
      return <MySelect {...props} />;

    case "choices":
      return <MyChoices {...props} />;

    case "textArea":
      return <MyTextArea {...props} />;

    case "render":
      return <MyRender {...props} />;

    default:
      return <MyTextField {...props} disabled={true} />;
  }
}
