import React, { useState, useEffect, useCallback } from "react";
import { useAjera } from "../lib/ajera";
import { useToast, useDisclosure } from '@chakra-ui/react'
import _ from 'lodash';

import { Fab, Fabs } from "../components";
import New from "./New";
import { TimeoutPromise, calcNumofApprovers } from "../lib";
import EmailMessage from "../components/EmailMessageModal";


function NewProposal({ edit, resubmit, state, updateState, navigate }) {

  const [proposalData, setProposalData] = useState({});
  const [PhaseKey, setPhaseKey] = useState(state?.PhaseKey);
  const [ProposalID, setProposalID] = useState(proposalData?.ID);
  const [isLoading, setIsLoading] = useState(Boolean(PhaseKey) && !ProposalID);
  console.log("NewProposal ", {edit, resubmit, PhaseKey, ProposalID, isLoading}); 

  const [prfData, setPrfData] = useState({});

  const ajera = useAjera();
  const cachedAjera = useAjera({ cached: true });
  const toast = useToast();

  const { isOpen: emailMessageIsOpen, onOpen: emailMessageOnOpen, onClose: emailMessageOnClose } = useDisclosure();
  const emailMessageCallback = useCallback(handleCreateNewProposal, [ajera, PhaseKey, proposalData, emailMessage]);
  const [emailMessage, setEmailMessage] = useState();

  useEffect(() => {
    
    const billingType = proposalData?.CF_ProposalBillingType?.Value || proposalData?.CF_PRFBillingType?.Value;
    const dollarAmount = proposalData?.CF_ProposalDollarAmount;
    if (billingType !== undefined && dollarAmount !== undefined) {
      const num = calcNumofApprovers(billingType, dollarAmount);
      console.log({num, billingType, dollarAmount})
      if (num) {
        setProposalData(prev => ({...prev, CF_NumOfApproversNeeded: num}));
      }
    }

  }, [
    proposalData?.CF_PRFBillingType?.Value,
    proposalData?.CF_ProposalBillingType?.Value,
    proposalData?.CF_ProposalDollarAmount
  ]);

  const handleSearch = async ({ key, id }) => {

    // Check that only one input is provided
    if ((Boolean(key) && Boolean(id)) || (!Boolean(key) && !Boolean(id))) {
      throw new Error("Must provide exactly one input, either key or id");
    }

    setIsLoading(true);
    
    const getProposal = async () => {

      const response = key
        ? await ajera.getProposal(parseInt(key))
        : await ajera.getProposalByID(parseInt(id));

      if (!response || !response?.Content) {
        throw new Error("Unable to retrieve proposal");
      }

      return response?.Content;
    };

    try {
      
      const proposal = await TimeoutPromise(getProposal());

      console.log(proposal);
      setPhaseKey(proposal.PhaseKey);
      setProposalID(proposal.ID);
      setProposalData(JSON.parse(JSON.stringify(proposal)));
      setPrfData(JSON.parse(JSON.stringify(proposal)));

    } catch (error) {

      console.error(error);
      setPhaseKey(null);
      setProposalID("");
      setProposalData({});

    } finally {

      setIsLoading(false);

    }
  };

  useEffect(() => {
    handleSearch({ key: PhaseKey });
  }, [PhaseKey]);

  function handleCancel() {
    navigate('view', { state: { PhaseKey }});
  }

  async function handleSave() {
     if (edit) {
      handleEditProposal();
    } else {
      emailMessageOnOpen();
    }
  }

  async function _handleEditProposal() {
    console.log("Updating Proposal: ", PhaseKey);
    
    setIsLoading(true);
    
    await TimeoutPromise(ajera.update000(PhaseKey, proposalData))
      .then(resp => {
        console.log("editProposal", resp);

        setIsLoading(false);
        navigate('view', { state: { PhaseKey }});
    
        toast({
          title: "Successfully Updated Proposal!!",
          status: "success",
          isClosable: true
        })

      })
      .catch(err => {
        setIsLoading(false);
      });
  }
  const handleEditProposal = useCallback(_handleEditProposal, [ajera, PhaseKey, proposalData, emailMessage]);

  async function handleCreateNewProposal() {
    console.log("Converting PRF to Proposal: ", PhaseKey);
    
    setIsLoading(true);

    _.set(proposalData, "CF_Stage", "Proposal")
    
    await TimeoutPromise(ajera.createProposal(PhaseKey, proposalData, emailMessage, resubmit))
      .then(resp => {

        console.log("createProposal", resp);

        setIsLoading(false);
        navigate('view', { state: { PhaseKey }});
    
        toast({
          title: "Successfully Submitted Proposal!!",
          status: "success",
          isClosable: true
        })

      })
      .catch(err => {
        setIsLoading(false);
      });
  }


  const form = [
    [
      { type: "spacer" },
      { type: "hr" },
      { label: "PROPOSAL FORM", type: "label" },
      { type: "hr" },
      { type: "spacer" },
    ],

    // Required Data
    [
      {
        label: "Description (include ID)",
        field: "Description",
        prffield: "Description"
      },
      { type: "spacer" },
      {
        type: "select",
        label: "Billing Type",
        isAjeraSelect: true,
        AllowEdits: false,
        options: ["T&M", "T&M NTE", "Fixed Price", "Other"],
        field: "CF_ProposalBillingType",
        prffield: edit ? ["CF_ProposalBillingType", "Value"] : ["CF_PRFBillingType", "Value"]
      },
      {
        type: "dollar",
        label: "Dollar Amount",
        field: "CF_ProposalDollarAmount",
        prffield: edit ? "CF_ProposalDollarAmount" : "CF_PRFEstAmtindollars"
      },
      {
        type: "hint",
        label: "Number of Approvers Needed: ", // + (proposalData.CF_NumOfApproversNeeded || "-"),
        field: "CF_NumOfApproversNeeded",
        default: proposalData.CF_NumOfApproversNeeded
      }
    ],

    // Other Data
    [
      { type: "spacer" },
      { type: "hr" },
      { type: "spacer" },
      { type: "spacer" },
      {
        label: "Expected Award Date",
        type: "date",
        // default: new Date().toISOString().slice(0, 10),
        field: "CF_ProjectExpectedAwardDate" // TODO: CF_ProposalExpectedAwardDate
      },
      {
        label: "Estimated Start Date",
        type: "date",
        xs: 6,
        // default: new Date().toISOString().slice(0, 10),
        field: "EstimatedStartDate"
      },
      {
        label: "Estimated Completion Date",
        type: "date",
        xs: 6,
        // default: new Date().toISOString().slice(0, 10),
        field: "EstimatedCompletionDate"
      },
    ],
    [
      {
        label: "Probability of Award",
        type: "percent",
        field: "CF_ProposalPropabilityOfAward" // TODO: Spelling CF_ProposalProbabilityOfAward
      },
      {
        label: "Industry",
        type: "select",
        isAjeraSelect: true,
        AllowEdits: false,
        options:  [ "Mining", "Oil & Gas", "Energy", "Food", "Power", "Misc" ],
        // options: async () => await ajera.listIndustry(), // TODO
        field: "CF_Industry",
        prffield: ["CF_Industry", "Value"]
      },
      {
        label: "Project Type",
        cols: 12,
        type: "select",
        // xs: 6,
        options: ["Project", "Placement", "Panels", "Equipment"],
        // options: () => https://localhost/method/?Method=ListProjectTypes
        field: "CF_ProposalProjectType",
        prffield: edit ? "CF_ProposalProjectType" : "CF_PRFProjectType"
      },
      {
        label: "Engineering Level",
        cols: 12,
        type: "select",
        xs: 12,
        isAjeraSelect: true,
        AllowEdits: true,
        options: [ "Pre-Feasibility", "Feasibility", "Preliminary", "Detailed" ],
        field: "CF_ProposalEngineeringLevel",
        prffield: edit ? ["CF_ProposalEngineeringLevel", "Value"] : ["CF_PRFEngineeringLevel", "Value"]
      },
      { type: "spacer" },
      {
        label: "Terms",
        type: "textArea",
        minRows: 3,
        field: "CF_ProposalTerms"
      },
      // { // TODO
      //   label: "Outcome",
      //   type: "select",
      //   options: ["win", "loss", "decline"],
      //   field: "CF_ProposalOutcome"
      // },
    ],

    // Cost Estimations
    [
      { type: "spacer" },
      { type: "spacer" },
      { type: "hr" },
      { label: "Cost Estimations (by dept)", type: "label" },
      { type: "hr" },
      { type: "spacer" },
      { type: "spacer" },
      {
        label: "Estimated Dollar: Controls",
        type: "dollar",
        xs: 6,
        field: "CF_ProposalEstDollarControls"
      },
      {
        label: "Estimated Hours: Controls",
        type: "hours",
        xs: 6,
        field: "CF_ProposalEstHoursControls"
      },
      {
        label: "Estimated Dollar: Mechanical",
        type: "dollar",
        xs: 6,
        field: "CF_ProposalEstDollarMechanical"
      },
      {
        label: "Estimated Hours: Mechanical",
        type: "hours",
        xs: 6,
        field: "CF_ProposalEstHoursMechanical"
      },
      {
        label: "Estimated Dollar: Electrical",
        type: "dollar",
        xs: 6,
        field: "CF_ProposalEstDollarElectrical"
      },
      {
        label: "Estimated Hours: Electrical",
        type: "hours",
        xs: 6,
        field: "CF_ProposalEstHoursElectrical"
      },
      {
        label: "Estimated Dollar: Structural",
        type: "dollar",
        xs: 6,
        field: "CF_ProposalEstDollarStructural"
      },
      {
        label: "Estimated Hours: Structural",
        type: "hours",
        xs: 6,
        field: "CF_ProposalEstHoursStructural"
      },
      {
        label: "Estimated Dollar: Total",
        type: "dollar",
        xs: 6,
        field: "CF_ProposalEstDollarTotal"
      },
      {
        label: "Estimated Hours: Total",
        type: "hours",
        xs: 6,
        field: "CF_ProposalEstHoursTotal"
      }
    ],
    [
      
      { type: "spacer" },
      { type: "hr" },
      { label: "Notes", type: "label" },
      { type: "hr" },
      { type: "spacer" },
      {
        type: "textArea",
        minRows: 12,
        field: "Notes",
      }
    ],
    [
      { type: "spacer" },
      { type: "spacer" },
    ],
  ];


  return (
    <>
      <New obj={proposalData} setObj={setProposalData} objOG={prfData} form={form} isLoading={isLoading} autofill={resubmit} />

      {!isLoading && <Fabs>
        <Fab onClick={handleSave} muiIcon="Save" tooltip="Submit Proposal" />
        <Fab onClick={handleCancel} muiIcon="Close" tooltip="Cancel" />
      </Fabs>}

      <EmailMessage
        isOpen={emailMessageIsOpen}
        onClose={emailMessageOnClose}
        text={emailMessage}
        setText={setEmailMessage}
        onSubmit={emailMessageCallback} />

    </>
  );
}

export default NewProposal;
