import React, { useState } from "react";

// Themeing
import { ChakraProvider, useToast } from '@chakra-ui/react'
import { ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import theme from "./lib/Theme";

// Components
import { Tabs, Tab as MuiTab, Box, Typography, Icon, Tooltip } from "@mui/material";
import { AddCircle, CalendarViewWeek, Home, RemoveRedEye } from "@mui/icons-material";

// Lib
import { API } from "./lib/api";

// Pages
import Actions from "./pages/Actions";
import NewPRF from "./pages/NewPRF";
import NewProposal from "./pages/NewProposal";
import List from "./pages/List";
import View from "./pages/View";
import ListProjects from "./pages/projects/ListProjects";
import Project from "./pages/projects/Project";


function Tab({ tooltip, ...props }) {
  return (
    <Tooltip title={tooltip}>
      <MuiTab {...props} />
    </Tooltip>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 9999,
    marginRight: 4,
    borderBottom: 0,
    backgroundColor: "#F3F2F1",
  },
  main: {
    marginRight: 4,
    paddingBottom: 12,
    paddingBottom: "200px",
  }
}));


function App(props) {
  const classes = useStyles();
  const toast = useToast();

  const [taskpane_type, setTaskPaneType] = useState(new URLSearchParams(window.location.search).get("taskpane_type"));
  const [currentPath, setCurrentPath] = useState("list");
  const [history, updateHistory] = useState([]);
  const [state, setState] = useState({ proposals: [], projects: [] });
  console.log("App", {history, currentPath})
  

  const navigate = (path, options) => {
    if (options && options.state) updateState(options.state);
    updateHistory(prev => ([...prev, currentPath]));
    path === "back" ? back() : setCurrentPath(path);
  }

  const back = () => {
    console.log("1", {history, currentPath})
    if (history.length === 0) return;
    const newPath = history[history.length - 1];
    updateHistory(prev => prev.length === 1 ? [] : prev.slice(0, -1));
    setCurrentPath(newPath);
    console.log("2", {history, currentPath, newPath})
  }

  const handleChange = (event, newValue) => {
    navigate(newValue);
  }

  const updateState = (data) => {
    setState({ ...state, ...data });
  }


  if (!props.isOfficeInitialized) {
    return (
      <div>
        <Typography>{props.title}</Typography>
        <Typography>Please sideload your addin to see app body.</Typography>
      </div>
    );
  }

  // Change "proposals" to "main"
  if (!["proposals", "projects", "actions"].includes(taskpane_type)) {
    return <Typography>taskpane_type '{taskpane_type || ''}' is not Supported</Typography>;
  }

  // Toast
  API.setToast(toast);

  // TODO: It'd be cool to Abstract out the listing and the Viewing,
  // TODO:  and this will be the only page explaining the differences

  const Container = ({ children }) => (
    <ChakraProvider>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ChakraProvider>
  );

  const Main = () => {

    function renderContent() {
      switch (currentPath) {
        case "prf":
          return <NewPRF state={state} updateState={updateState} navigate={navigate} back={back} />;
        case "proposal":
          return <NewProposal state={state} updateState={updateState} navigate={navigate} back={back} />;
        case "prf-edit":
          return <NewPRF edit={true} state={state} updateState={updateState} navigate={navigate} back={back} />;
        case "proposal-edit":
          return <NewProposal edit={true} state={state} updateState={updateState} navigate={navigate} back={back} />;
        case "proposal-resubmit": 
          return <NewProposal edit={true} resubmit={true} state={state} updateState={updateState} navigate={navigate} back={back} />;
        case "list":
          return <List state={state} updateState={updateState} navigate={navigate} back={back} />;
        case "view":
          return <View state={state} updateState={updateState} navigate={navigate} back={back} />;
        default:
          return <Typography>currentPath '{currentPath}' is not Supported</Typography>;          
      }
    }

    return (
      <>
        <Box className={classes.header}>
          <Tabs value={currentPath} onChange={handleChange} variant="fullWidth">
            {/*
              <Tab
                value="back"
                sx={{
                  p: 0,
                  minWidth: (history.length) ? "32px" : "0px",
                  maxWidth: (history.length) ? "48px" : "0px"
                }}
                tooltip={"Back"}
                icon={<Icon as={ArrowBackIosNew} />}
              />
            */}
                
            <Tab
              value="prf"
              sx={{ p: 0, minWidth: "0px", maxWidth: "0px" }}
              tooltip={"Create a new PRF"}
              icon={<Icon as={AddCircle} />}
            />

            <Tab
              value="proposal"
              sx={{ p: 0, minWidth: "0px", maxWidth: "0px" }}
              tooltip={"Convert PRF to Proposal"}
              icon={<Icon as={CalendarViewWeek} />}
            />
            
            <Tab
              value="prf-edit"
              sx={{ p: 0, minWidth: "0px", maxWidth: "0px" }}
              tooltip={"Edit PRF"}
              icon={<Icon as={AddCircle} />}  
            />

            <Tab
              value="proposal-edit"
              sx={{ p: 0, minWidth: "0px", maxWidth: "0px" }}
              tooltip={"Edit Proposal"}
              icon={<Icon as={CalendarViewWeek} />}
            />

            <Tab
              value="list"
              sx={{ p: 0, minWidth: "32px" }}
              tooltip={"List Proposals/PRFs"}
              icon={<Icon as={Home} /* FormatListBulleted */ />}
            />

            <Tab
              value="view"
              sx={{ p: 0, minWidth: "32px" }}
              tooltip={"View Proposal/PRF info"}
              icon={<Icon as={RemoveRedEye} />}
            />

          </Tabs>
        </Box>

        <Box className={classes.main}>
          {renderContent()}
        </Box>

      </>
    );
  }

  const Projects = () => {
    
    function renderContent() {
      switch (currentPath) {
        case "list":
          return <ListProjects state={state} updateState={updateState} navigate={navigate} back={back} />;
        case "view":
          return <Project state={state} updateState={updateState} navigate={navigate} back={back} />;
        default: 
          return <Typography>currentPath '{currentPath}' is not Supported</Typography>
      }
    }

    return (
      <>
        <Box className={classes.header}>
          <Tabs value={currentPath} onChange={handleChange} variant="fullWidth">
            
            <Tab
              value="list"
              sx={{ p: 0, minWidth: "32px" }}
              tooltip={"List Projects"}
              icon={<Icon as={Home} /* FormatListBulleted */ />}
            />

            <Tab
              value="view"
              sx={{ p: 0, minWidth: "32px" }}
              tooltip={"View Project"}
              icon={<Icon as={RemoveRedEye} />}
            />

          </Tabs>
        </Box>

        <Box className={classes.main}>
          {renderContent()}
        </Box>
      </>
    );
  }

  switch (taskpane_type) {
    case "proposals":
      return (
        <Container>
          <Main />
        </Container>
      );
    case "projects":
      return (
        <Container>
          <Projects />
        </Container>
      );
    case "actions":
      return (
        <Container>
          <Actions />
        </Container>
      );
    default:
      return <Typography>Unreachable Code: taskpane_type '{taskpane_type}'</Typography>;
  }
}

export default App;
