import * as React from "react";
import { createRoot } from "react-dom/client";

import { AppContainer } from "react-hot-loader";

import { initializeIcons } from "@fluentui/font-icons-mdl2";

// console.log = function() {};

import App from "./App";
initializeIcons();


let isOfficeInitialized = false;

const title = "PSE - PRF & Proposal Management System";
const root = createRoot(document.getElementById("container") );


const render = (Component) => {
  console.log({ isOfficeInitialized, Office, title, root })

  root.render(
    <AppContainer>
      <Component title={title} isOfficeInitialized={isOfficeInitialized} />
    </AppContainer>
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});

/* Initial render showing a progress bar */
render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
