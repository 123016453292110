import React from "react";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    height: "50vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "24px 0px",

    rowGap: 24,
    color: "#357EC7",
    "& p": {
      fontSize: "1.5rem",
    }
  }
}));


const Loading = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <CircularProgress />
      <Typography component="p">
        {text}
      </Typography>
    </div>
  );
}

export default Loading;