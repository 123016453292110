import React, { useState, useEffect } from "react";

import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAPI, useGraphAPI, useActionsAPI } from "../lib/api";
import { FormItem } from "../components";
import { useAjera } from "../lib/ajera";

// #F0F0F0
const useStyles = makeStyles((theme) => ({
  main: {
    margin: "8px 4px",
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    "& > *": {
      flex: "0 0 100%",
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      boxShadow: "0px 0px 4px #bbbbbb",
      borderRadius: "8px",
      padding: "8px",
    },
  },
}));


function SendMail() {

  const email = Office.context.mailbox.userProfile.emailAddress;

  const [isLoading, setIsLoading] = useState(false);

  const [to, setTo] = useState(email);
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  
  const graph = useGraphAPI();


  async function handleSendMail(e) {
    setIsLoading(true);
    await graph.sendMail({ to, subject, text });
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <Typography>Send a Toolbox Email</Typography>
      <TextField
        placeholder="to"
        size="small"
        value={to}
        onChange={(e) => setTo(e.target.value)}
      />
      <TextField
        placeholder="subject"
        size="small"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <TextField
        placeholder="text"
        multiline
        rows={3}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <Button variant="contained" onClick={handleSendMail}>
        send
      </Button>
    </Box>
  );
}

function SendViewMail() {
  
  const graph = useGraphAPI();
  const ajera = useAjera();

  const [isLoading, setIsLoading] = useState(false);

  const [text, setText] = useState("");
  const [Stage, setStage] = useState("");
  const [ProposalKey, setProposalKey] = useState("");

  const [stages, setStages] = useState([
    "PRF",
    "ApprovedPRF",
    "Proposal",
    "ProposalApproved",
    "ProposalCompletelyApproved",
    "ProposalSubmitted",
    "Awarded",
    "Lost",
    "Active",
    "InActive"
  ]);
  const [proposals, setProposals] = useState([]);
  useEffect(() => {
    ajera
      .listProposals()
      .then(resp => {
        console.log({proposals: resp?.Content?.map?.(x => x?.PhaseKey)})
        setProposals(resp?.Content?.map?.(x => ({value: x?.PhaseKey, text: x?.Description})))
      })
  }, [])
  

  async function handleSendMail() {
    setIsLoading(true);
    await graph.sendViewMail(text, Stage, ProposalKey);
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>

      <Typography>Send a follow-up Email</Typography>

      <FormItem type="select" label="Stage"
        options={stages}
        onChange={(e, v) => {
          setStage(v)
        }}
      />

      <FormItem type="select" label="Proposal"
        options={proposals}
        onChange={(e, v) => {
          setProposalKey(v)
        }}
      />

      <TextField
        placeholder="text"
        multiline
        rows={3}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      <Button variant="contained" onClick={handleSendMail}>
        send
      </Button>

    </Box>
  );
}

export default function Actions() {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <SendMail />
      <SendViewMail />
    </Box>
  );
}
