import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import {
  sheet as import_sheet,
  blueHex,
  mediumBlueHex,
  lightBlueHex,
  redHex,
  mediumRedHex,
  lightRedHex,
  Container,
  HR,
  Spacer,
  Label,
  Table,
  Notes,
  Contacts,
  Header,
  Badge
} from "../../components/styles"

import { AbstractBarChart, ReactVisBarSeries, BarChart, PolarAxis } from "../../components/charts";

import { Fab, Fabs, Loading, SearchBar, FormItem } from "../../components";

import { useAjera } from "../../lib/ajera";
import { useToast } from "@chakra-ui/react";
import { DateFormatting, TimeoutPromise, NumberFormatter } from "../../lib";
import { Box, LinearProgress } from "@mui/material";


function ProgressTD({ children, value, good=true, goodcolor, badcolor }) {

  return (
    <Box
      component="td"
      sx={{
        position: 'relative',
        zIndex: 1,

        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: Math.max(0, Math.min(100, (value * 100))) + '%',
          height: '100%',
          backgroundColor: good ? (goodcolor ?? mediumBlueHex) : (badcolor ?? mediumRedHex),
          zIndex: -1
        }
      }}
    >
      
      {children}
    </Box>
  )
}


export default function Projects({ state, navigate }) {
  const [projectData, setProjectData] = useState({});
  const [ProjectKey, setProjectKey] = useState(state?.ProjectKey);
  const [ProjectID, setProjectID] = useState(projectData?.ID);
  const [isLoading, setIsLoading] = useState(Boolean(ProjectKey) && !ProjectID);

  const ajera = useAjera();
  const toast = useToast();

  const handleSearch = async ({ key, id }) => {
    // Check that only one input is provided
    if ((Boolean(key) && Boolean(id)) || (!Boolean(key) && !Boolean(id))) {
      throw new Error("Must provide exactly one input, either key or id");
    }

    setIsLoading(true);
    
    const getProject = async () => {

      const response = key
        ? await ajera.getProjectTotals(parseInt(key))
        : await ajera.getProjectTotalsByID(parseInt(id));

      if (!response) {
        throw new Error("Unable to retrieve Project");
      }

      return response;
    };

    try {

      const project = await TimeoutPromise(getProject());

      console.log(project);
      setProjectKey(project.PhaseKey);
      setProjectID(project.ID);
      setProjectData(project);

    } catch (e) {

      console.error(e);
      setProjectKey("");
      setProjectID("");
      setProjectData({});
      
      toast({
        title: e.message,
        status: "error",
        isClosable: true
      })
      
    } finally {

      setIsLoading(false);

    }
  };
  useEffect(() => {
    handleSearch({ key: ProjectKey });
  }, [ProjectKey]);

  const handleChange = (event, newVal) => {
    console.log("Changing data: ", event, newVal);
  };

  const handleSave = () => {
    console.log("Saving");
  };

  if (isLoading) {
    return (
      <Loading text={ProjectKey ? `Key: ${ProjectKey}` : `ID: ${ProjectID}`} />
    );
  }

  let {

    ActualStartDate,
    ActualCompletionDate,
    EstimatedStartDate,
    EstimatedCompletionDate,

    TotalContractAmount: TCA, // 556810
    Spent: DSpent, // 261,511
    "Hours Worked": HSpent, // 2199
    HoursCostBudget: HCB, // 3847

    ReportedPercentComplete: RPC, // 50%
    ReportedPercentCompleteDate

  } = projectData;
  
  ActualStartDate         = !ActualStartDate         ? ActualStartDate         : new Date(ActualStartDate);
  ActualCompletionDate    = !ActualCompletionDate    ? ActualCompletionDate    : new Date(ActualCompletionDate);
  EstimatedStartDate      = !EstimatedStartDate      ? EstimatedStartDate      : new Date(EstimatedStartDate);
  EstimatedCompletionDate = !EstimatedCompletionDate ? EstimatedCompletionDate : new Date(EstimatedCompletionDate);
  
  // TCA = 556810
  // DSpent = 261511
  // HSpent = 2199
  // HCB = 3847
  // RPC = 50

  window.pd = projectData;
  window.projectData = projectData;
  // window.findBilledHours = (obj, path = [], hoursKey='Hours Worked') => { // hoursKey='Billed Hours'

  //   if (typeof obj !== 'object') {
  //     return
  //   }

  //   if (Array.isArray(obj)) {

  //     for (let i = 0; i < obj.length; i++) {
  //       const newPath = [...path, i];
  //       findBilledHours(obj[i], newPath);
  //     }

  //   } else {

  //     for (const key in obj) {
  //       const newPath = [...path, key];
        
  //       if (key === 'phase' && Array.isArray(obj[key])) {

  //         for (let i = 0; i < obj[key].length; i++) {
  //           const subPath = [...newPath, 'phase', i];
  //           findBilledHours(obj[key][i], subPath);
  //         }

  //       } else if (key === hoursKey) {

  //         // console.log(`${newPath.join('.')}`)
  //         console.log(`(${obj['Description']}, ${obj[hoursKey]})`);

  //       } else {

  //         findBilledHours(obj[key], newPath);

  //       }
  //     }

  //   }

  // }
  // findBilledHours(pd)

  RPC = RPC / 100

  const DEV = (RPC * TCA);
  const HEV = (RPC * HCB);

  const CPI  = DEV / DSpent;
  const LPI  = HEV / HSpent;

  const EVM = (CPI + LPI) / 2;

  const CV   = DEV - DSpent;
  const CVP  = CV / DEV;
  const TCPI = (TCA - DEV) / (TCA - DSpent);

  // Dollar Calcs
  const DLEAC = DSpent + ((TCA - DEV) / CPI);
  const DLVAC = TCA - DLEAC;
  const DLETC = DLEAC - DSpent;

  const D_EAC = DSpent + (TCA - DEV);
  const D_VAC = TCA - D_EAC;
  const D_ETC = D_EAC - DSpent;
  
  const DEAC  = (RPC > 0.35) ? DLEAC : D_EAC;
  const DVAC  = (RPC > 0.35) ? DLVAC : D_VAC;
  const DETC  = (RPC > 0.35) ? DLETC : D_ETC;

  // Dollar Calcs
  const HLEAC = HSpent + ((HCB - HEV) / LPI);
  const HLVAC = HCB - HLEAC;
  const HLETC = HLEAC - HSpent;

  const H_EAC = HSpent + (HCB - HEV);
  const H_VAC = HCB - H_EAC;
  const H_ETC = H_EAC - HSpent;
  
  const HEAC  = (RPC > 0.35) ? HLEAC : H_EAC;
  const HVAC  = (RPC > 0.35) ? HLVAC : H_VAC;
  const HETC  = (RPC > 0.35) ? HLETC : H_ETC;


  const APC  = DSpent / DEAC;   // ActualPercentComplete
  const SPC  = DSpent / TCA;   // SpentPercentComplete

  const LaborScheduledProfit = projectData?.LaborContractAmount - projectData?.LaborCostBudget
  console.log({DEAC, TCA, LaborScheduledProfit})


  const evDollarsChartItems = [
    { label:  "Spend", value: DSpent },
    { label:  "EV",    value: DEV    },
    { label:  "EAC",   value: DEAC    },
  ]
  const evDollarsChartMax = TCA;

  
  const evHoursChartItems = [
    { label:  "Spend", value: HSpent },
    { label:  "EV",    value: HEV    },
    { label:  "EAC",   value: HEAC    },
  ]
  const evHoursChartMax = HCB;

  
  const projectDays = DateFormatting.getDifferenceInDays(ActualStartDate, EstimatedCompletionDate);
  const daysFromEndDate = DateFormatting.getDifferenceInDays(new Date(), EstimatedCompletionDate);

  const dateChartItems = [
    { label: "DaysLeft", value: projectDays - daysFromEndDate },
  ];
  const dateChartMax = projectDays;

  console.log({projectDays, daysFromEndDate, ActualStartDate, EstimatedCompletionDate})

  const polarData = {
    '%C':   RPC,
    'A-%C': APC,
    'S-%C': SPC,
    'EVM':  EVM,
    'CVP':  CVP,
    'CPI':  CPI,
    'LPI':  LPI,
    'TCPI': TCPI,
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>

      <FormItem type="spacer" />

      <SearchBar
        callback={async () => state?.projects || (await ajera.listProjects()).Projects}
        label="Project ID"
        value={ProjectID}
        onChange={value => {
          setProjectKey(value.ProjectKey);
          setProjectID(value.ID);
          handleSearch({ key: value.ProjectKey });
        }}
      />

      <Container>

        <Header text={projectData?.Description}>
          <Badge text={projectData?.ID} color={blueHex} />
        </Header>

        <HR />

        <Table>
          <tbody>

            <tr><td>Client</td><td>{projectData?.InvoiceGroups?.[0]?.Client?.Description}</td></tr>
            <tr><td>PM</td><td>{(projectData?.ProjectManager?.FirstName || '')} {(projectData?.ProjectManager?.LastName || '')}</td></tr>
            <tr><td>PIC</td><td>{(projectData?.PrincipalInCharge?.FirstName || '')} {(projectData?.PrincipalInCharge?.LastName || '')}</td></tr>
            <tr><td>MC</td><td>{(projectData?.MarketingContact?.FirstName || '') + ' ' + (projectData?.MarketingContact?.LastName || '')}</td></tr>

            <tr><td>Billing Type</td><td>{projectData?.BillingType}</td></tr>
            <tr><td>Billing Email</td><td>{projectData?.CF_BillingEmail}</td></tr>
            <tr><td>Billing Notes</td><td>{projectData?.CF_BillingNotes}</td></tr>

          </tbody>
        </Table>

        <HR />

        <Table>
          <tbody>
            
            <tr><td>Est. End Date</td><ProgressTD value={Math.abs(projectDays - daysFromEndDate) / projectDays} good={daysFromEndDate >= 0}>{DateFormatting.toViewDate(ActualStartDate) + ' - ' + DateFormatting.toViewDate(EstimatedCompletionDate)}</ProgressTD></tr>
            <tr><td>Reported % Complete</td><ProgressTD value={RPC} good={0 <= RPC && RPC <= 1}>{NumberFormatter.toPercent(RPC)}</ProgressTD></tr>
            
            <tr><td>Spent $</td><ProgressTD value={DSpent / TCA} good={(DSpent / TCA) <= 1}>{NumberFormatter.toDollar(DSpent) + ' / ' + NumberFormatter.toDollar(TCA)}</ProgressTD></tr>
            <tr><td>Hours  </td><ProgressTD value={HSpent / HCB} good={(HSpent / HCB) <= 1}>{NumberFormatter.toString(HSpent) + ' / ' + HCB}</ProgressTD></tr>
            {/* TODO: Make the Red count up to show how intensly they are over budget */}
            {/* TODO: I think it just needs to be a small red if it's not that over */}

          </tbody>
        </Table>

        <HR />

        <Label label="Dollars" inline />
        <Table>
          <tbody>

            <tr><td>Spent $</td><ProgressTD value={DSpent / TCA} good={DSpent / TCA <= 1}>{NumberFormatter.toDollar(DSpent)}</ProgressTD></tr>
            <tr><td>Earned Value (EV)</td><ProgressTD value={DEV / TCA} good={DEV / TCA <= 1}>{NumberFormatter.toDollar(DEV)}</ProgressTD></tr>
            <tr><td>Estimate At Complete (EAC)</td><ProgressTD value={DEAC / TCA} good={DEAC / TCA <= 1}>{NumberFormatter.toDollar(DEAC)}</ProgressTD></tr>
            <tr><td>Estimated Over/Under</td><ProgressTD value={(DEAC - TCA) / LaborScheduledProfit} good={(DEAC - TCA) <= 0}>{NumberFormatter.toDollar(DEAC - TCA)}</ProgressTD></tr>

          </tbody>
        </Table>

        <Label label="Hours" inline />
        <Table>
          <tbody>

            <tr><td>Hours Worked</td><ProgressTD value={HSpent / HCB} good={HSpent / HCB <= 1}>{NumberFormatter.toString(HSpent)}</ProgressTD></tr>
            <tr><td>Earned Value (EV)</td><ProgressTD value={HEV / HCB} good={HEV / HCB <= 1}>{NumberFormatter.toString(HEV)}</ProgressTD></tr>
            <tr><td>Estimate At Complete (EAC)</td><ProgressTD value={HEAC / HCB} good={HEAC / HCB <= 1}>{NumberFormatter.toString(HEAC)}</ProgressTD></tr>
            <tr><td>Estimate Over/Under</td><ProgressTD value={0} good={true}>{NumberFormatter.toString(HEAC - HCB)}</ProgressTD></tr>

          </tbody>
        </Table>

        {/* <Spacer />
        <Spacer /> */}

        {/* <BarChart
          dollars={{total: projectData?.TotalContractAmount, value: projectData?.Spent}}
          hours={{total: projectData?.["HoursCostBudget"], value: projectData?.["Hours Worked"]}}
        /> */}

        <HR />

        <Table>
          <tbody>
            <tr><td>WIP Total</td><td>{NumberFormatter.toDollar(projectData?.WIP)}</td></tr>
            <tr><td>WIP Hours</td><td>{NumberFormatter.toString(projectData?.['WIP Hours'])}</td></tr>
          </tbody>
        </Table>

        <HR />

        <Table>
          <tbody>      
            
            <tr><td>Hours Cost Budget</td><td>{projectData?.HoursCostBudget}</td></tr>
            <tr><td>Labor Cost Budget</td><td>{NumberFormatter.toDollar(projectData?.LaborCostBudget)}</td></tr>
            <tr><td>Expense Cost Budget</td><td>{NumberFormatter.toDollar(projectData?.ExpenseCostBudget)}</td></tr>
            <tr><td>Total Contract Amount</td><td>{NumberFormatter.toDollar(projectData?.TotalContractAmount)}</td></tr>
            <tr><td>Labor Contract Amount</td><td>{NumberFormatter.toDollar(projectData?.LaborContractAmount)}</td></tr>
            <tr><td>Expense Contract Amount</td><td>{NumberFormatter.toDollar(projectData?.ExpenseContractAmount)}</td></tr>
          </tbody>
        </Table>

        <Label label="KPI's" />
        <Label label="Schedule" inline />
        <Table>
          <tbody>
            
            <tr><td>Est. End Date</td><ProgressTD value={Math.abs(projectDays - daysFromEndDate) / projectDays} good={daysFromEndDate >= 0}>{DateFormatting.toViewDate(ActualStartDate) + ' - ' + DateFormatting.toViewDate(EstimatedCompletionDate)}</ProgressTD></tr>
            <tr><td>Reported % Complete</td><ProgressTD value={RPC} good={0 <= RPC && RPC <= 1}>{NumberFormatter.toPercent(RPC)}</ProgressTD></tr>
            
          </tbody>
        </Table>
        
        <Label label="Spent / Budget" inline />
        <Table>
          <tbody>

            <tr><td>TotalContractAmount $</td><td>{NumberFormatter.toDollar(TCA)}</td></tr>
            <tr><td>Spent $</td><ProgressTD value={DSpent / TCA} good={DSpent / TCA <= 1}>{NumberFormatter.toDollar(DSpent)}</ProgressTD></tr>
            <tr><td>HoursCostBudget</td><td>{HCB}</td></tr>
            <tr><td>Hours Worked</td><ProgressTD value={HSpent / HCB} good={HSpent / HCB <= 1}>{NumberFormatter.toString(HSpent)}</ProgressTD></tr>
                
          </tbody>
        </Table>
        
        <Label label="Earned Value" inline />
        <Table>
          <tbody>

            <tr><td>Earned Value Metric (EVM)</td><ProgressTD value={EVM} good={true}>{NumberFormatter.toPercent(EVM)}</ProgressTD></tr>
            <tr><td>Dollars Earned Value (EV)</td><ProgressTD value={DEV / TCA} good={DEV / TCA <= 1}>{NumberFormatter.toDollar(DEV)}</ProgressTD></tr>
            <tr><td>Hours Earned Value (EV)</td><ProgressTD value={HEV / HCB} good={HEV / HCB <= 1}>{NumberFormatter.toString(HEV)}</ProgressTD></tr>

          </tbody>
        </Table>
        
        <Label label="Performance" inline />
        <Table>
          <tbody>

            <tr><td>Cost Performance Index (CPI)</td><ProgressTD value={CPI} good={CPI > 1}>{NumberFormatter.toPercent(CPI)}</ProgressTD></tr>
            <tr><td>Labor Performance Index (LPI)</td><ProgressTD value={LPI} good={LPI > 1}>{NumberFormatter.toPercent(LPI)}</ProgressTD></tr>
            <tr><td>To Complete CPI (TCPI)</td><ProgressTD value={TCPI} good={TCPI <= 1}>{NumberFormatter.toPercent(TCPI)}</ProgressTD></tr>
            <tr><td>Cost Variance (CV)</td><td>{NumberFormatter.toString(CV)}</td></tr>
            <tr><td>Cost Variance - % of Budget (CV%)  </td><ProgressTD value={CVP} good={true}>{NumberFormatter.toPercent(CVP)}</ProgressTD></tr>
    
          </tbody>
        </Table>
        
        <Label label="Estimates" inline />
        <Table>
          <tbody>

            <tr><td>Actual % Complete</td><ProgressTD value={APC} good={true}>{NumberFormatter.toPercent(APC)}</ProgressTD></tr>
            <tr><td>Spent % Complete</td><ProgressTD value={SPC} good={true}>{NumberFormatter.toPercent(SPC)}</ProgressTD></tr>
            
            <tr><td>Dollars Estimate At Complete (EAC)</td><ProgressTD value={DEAC / TCA} good={DEAC / TCA <= 1}>{NumberFormatter.toDollar(DEAC)}</ProgressTD></tr>
            <tr><td>Dollars Variance at Completion (VAC)</td><td>{NumberFormatter.toDollar(DVAC)}</td></tr>
            <tr><td>Dollars Estimate to Complete (ETC)</td><td>{NumberFormatter.toDollar(DETC)}</td></tr>

            <tr><td>Hours Estimate At Complete (EAC)</td><ProgressTD value={HEAC / HCB} good={HEAC / HCB <= 1}>{NumberFormatter.toString(HEAC)}</ProgressTD></tr>
            <tr><td>Hours Variance at Completion (VAC)</td><td>{NumberFormatter.toString(HVAC)}</td></tr>
            <tr><td>Hours Estimate to Complete (ETC)</td><td>{NumberFormatter.toString(HETC)}</td></tr>
            
          </tbody>
        </Table>

        
        {/* <Box sx={{ width: '100%' }}>
          <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} /> 
        </Box> */}

        {/* <PolarAxis data={polarData} /> */}

        <Label label="Contacts" />
        <Contacts contacts={projectData?.Contacts} />

        <Label label="Notes" />
        <Notes>{projectData?.Notes}</Notes>

        {/* <tr><td>Email for Accounts payable</td><td>{projectData?.}</td></tr> */}
        {/* `Charts for things?` */}

      </Container>

      {/* <Fabs>
        <Fab label="Save" onClick={handleSave} muiIcon="SaveOutlined" />
      </Fabs> */}
      
    </div>
  );
}
