import React from "react";
import { Box, Button, Modal, TextField, Typography, FormControl, FormLabel, FormControlLabel, FormHelperText } from "@mui/material";

function EmailMessage({ isOpen, onOpen, onClose, text, setText, onSubmit }) {
  return (
    <Modal
      id={'email-modal'}
      open={isOpen}
      onClose={onClose}
    >

      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 2
      }}>

        <FormControl sx={{ width: '100%' }} variant="standard">
          
          <TextField
            label="Email Message"
            value={text}
            onChange={(e) => setText(e.target.value)}
            multiline
            rows={8}
          ></TextField>
        
          <div style={{ display: 'flex' }}>
            <Button variant="contained" color="primary" sx={{ mt: 2, ml: 'auto' }} onClick={() => {
              console.log({onSubmit})
              onSubmit();
              onClose();
            }}>
              Submit
            </Button>
          </div>
          
        </FormControl>

      </Box>
    </Modal>
  )
}

export default EmailMessage;
