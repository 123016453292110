import React from "react";
import _ from 'lodash';

import { Grid } from "@mui/material";
import { FormItem, Loading } from "../components";


/**
 * @param options
 * @param options.obj
 * 
 * label ->  string || null
 * type  ->  text   || date | checkbox | range | hint | dollar | choices | textArea
 * cols  ->  6      || 12
 *
 * types:
 *  - text:     [label, placeholder, default]
 *  - date:     [label, default]
 *  - checkbox:      [label, default]
 *  - range:    [label, min, max, default, hint]
 *  - hint:     [label, value]
 *  - dollar:   [label, placeholder, default, hint]
 *  - choices:  [label, chioces, placeholder, default]
 *  - textArea: [label, placeholder, default]
 * @param options.setObj
 * @param options.form
 * @param options.isLoading
 */
function New({ obj, setObj, objOG, form, isLoading, autofill=false, showHelperText=true }) {
  console.log("New", {showHelperText, objOG: objOG?.Description, obj: obj?.Description})

  if (isLoading) {
    return <Loading />;
  }

  return (

    <Grid container rowSpacing={0}>
      {form.flat().map((item, i) => {

        // TODO: i couldn't get the AutoFill working

        const parse = item.parse;
        delete item.parse;

        function buildValueFromItem(item) {

          let value = parse ?
            parse(item.default || item.value || null) :
            item.default || item.value || null;
            
          if (!!value && item.field) {
            if (item.type === "select" && item.isAjeraSelect) {
              return { Value: value, AllowEdits: item.AllowEdits, Values: item.options } // TODO: idk about the allow Edits
            }
            return value
          }

          return undefined;
        }

        function getValue(initValue) {
          if (!!initValue && item.field) {
            if (!!initValue?.Value && item.type === "select" && item.isAjeraSelect) {
              return initValue?.Value;
            }
            return initValue
          }
        }

        function getValueOnChange(e, v) {

          if (!item.field) {
            return undefined;
          }

          var value = item.type === "checkbox" ? e.target.checked : (parse ? parse(v) : v);

          if (!!value && item.field) {
            if (item.type === "select" && item.isAjeraSelect) {
              return { Value: value, AllowEdits: item.AllowEdits, Values: item.options } // TODO: idk about the allow Edits
            }
            return value
          }

          return undefined;

        }

        const initValue = buildValueFromItem(item);          
        if (initValue) {
          _.set(obj, item.field, initValue);
        }

        var helperText = getValue(_.get(objOG, item.prffield || item.field));
        if (!showHelperText) {
          helperText = undefined;
        }

        return (
          <Grid item key={i} xs={item.xs || 12} sm={item.sm} md={item.md} lg={item.lg} xl={item.xl}>
            <FormItem key={i + 'spacer'} type="spacer" />
            <FormItem
              key={i}
              type={item.type || "text"}
              {...item}
              label={item.label}
              helperText={ helperText }
              default={ getValue(initValue) }
              onChange={(e, v) => {
                const changedValue = getValueOnChange(e, v);
                console.log(item.field, changedValue)
                if (changedValue) {
                  const newObj = _.cloneDeep(obj);
                  _.set(newObj, item.field, changedValue);
                  setObj(newObj);

                  // _.set(obj, item.field, changedValue);
                }
                item?.onChange?.(e, v)
              }}
            />
          </Grid>
        )
      })}
    </Grid>
      
  );
}

export default New;
