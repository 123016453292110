import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      // main: '#06c'
      // main: "#357EC7",
      main: "#0F6CBD",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#ff4081',
      // main: "#357EC7",
      main: "#0F6CBD",
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    accent: {
      // main: "#357EC7",
      main: "#0F6CBD",
      // main: '#06c'
    },
    button: {},
    // error: will use the default color
  },
});

export default theme;
