import React from "react";


import { create } from "jss";
import nested from "jss-plugin-nested";
import camelCase from "jss-plugin-camel-case";
import { jssPreset } from "@mui/styles";

import {
  sheet as import_sheet,
  Container,
  HR,
  Spacer,
  Label,
  Table,
  Notes,
  Header,
  Contacts,
  Badge
} from "../components/styles"

import { NumberFormatter } from "../lib"

export const sheet = import_sheet;

const StageColors = {
  "PRF": "#E74C3C", // (darker red)
  "ApprovedPRF": "#3498DB", // (blue)
  "Proposal": "#E74C3C", // (darker red, same as PRF)
  "ProposalApproved": "#3498DB", // (blue, same as ApprovedPRF)
  "ProposalSubmitted": "#919191", // (light gray)
  "Awarded": "#27AE60", // (darker green)
  "Lost": "#BDC3C7", // (darker green)
  "Active": "#BDC3C7", // (light gray)
  "InActive": "#BDC3C7", // (light gray)
}

function ApproversTable({ approvers, employees }) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Date</th>
          <th>FirstName</th>
          <th>LastName</th>
        </tr>
      </thead>
      <tbody>
        {approvers
          // ?.filter(([ id, date ]) => !!id && !!date)
          ?.map(([ id, date ]) => ({
            date: date,
            ...employees.find(e => e.EmployeeKey == id)
          }))
          ?.map(({ FirstName, LastName, date }, i) => (
            <tr key={i}>
              <td>{date || '-'}</td>
              <td>{FirstName || '-'}</td>
              <td>{LastName || '-'}</td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  )
}

function ViewHTML(props) {
  const { proposal, employees } = props;

  const Stage = proposal.CF_Stage;
  const isPrf = ["PRF", "ApprovedPRF"].includes(Stage);
  const isProposal = ["Proposal", "ProposalApproved", "ProposalSubmitted"].includes(Stage);
  const isCompleted = ["Awarded", "Lost", "Declined", "Active", "InActive"].includes(Stage);
  const isNotPrf = isProposal || isCompleted;
  const unknownStage = [isPrf, isProposal, isCompleted].some(x => x === true);

  const estimations = {
    "Controls":   { dollars: proposal.CF_ProposalEstDollarControls,   hours: proposal.CF_ProposalEstHoursControls },
    "Mechanical": { dollars: proposal.CF_ProposalEstDollarMechanical, hours: proposal.CF_ProposalEstHoursMechanical },
    "Electrical": { dollars: proposal.CF_ProposalEstDollarElectrical, hours: proposal.CF_ProposalEstHoursElectrical },
    "Structural": { dollars: proposal.CF_ProposalEstDollarStructural, hours: proposal.CF_ProposalEstHoursStructural },
    "Total":      { dollars: proposal.CF_ProposalEstDollarTotal,      hours: proposal.CF_ProposalEstHoursTotal }
  }

  const prfApprovers = [
    [proposal.CF_PRFApproverEmployeeID, proposal.CF_PRFDateTimeApproved]
  ];  // proposal.CF_PRFIsApproved

  const _proposalApprovers = [
    [proposal.CF_ProposalApprover1EmployeeID, proposal.CF_ProposalApprover1Date],
    [proposal.CF_ProposalApprover2EmployeeID, proposal.CF_ProposalApprover2Date],
    [proposal.CF_ProposalApprover3EmployeeID, proposal.CF_ProposalApprover3Date],
    [proposal.CF_ProposalApprover4EmployeeID, proposal.CF_ProposalApprover4Date]
  ];
  const proposalApprovers = _proposalApprovers.filter(x => x[0] || x[1]);

  return (
    
    <Container>

      <Header text={proposal.Description}>
        {proposal.CF_Stage && (
          <Badge text={proposal.CF_Stage} color={StageColors[proposal.CF_Stage]} />
        )}
      </Header>
      
      <HR />

      <Table>
        <tbody>

          <tr><td>PM</td><td>{(proposal?.ProjectManager?.FirstName || '') + ' ' + (proposal?.ProjectManager?.LastName || '')}</td></tr>
          <tr><td>PIC</td><td>{(proposal?.PrincipalInCharge?.FirstName || '') + ' ' + (proposal?.PrincipalInCharge?.LastName || '')}</td></tr>
          <tr><td>MC</td><td>{(proposal?.MarketingContact?.FirstName || '') + ' ' + (proposal?.MarketingContact?.LastName || '')}</td></tr>
          <tr><td>Industry</td><td>{proposal.CF_Industry?.Value}</td></tr>
          <tr><td>Is Funded</td><td>{proposal.CF_IsFunded ? 'Yes' : 'No'}</td></tr>
          <tr><td>Substantial Equipment</td><td>{proposal.CF_PRFSubstantialEquipment ? 'Yes' : 'No'}</td></tr>

          {isPrf ?
            (<>
              <tr><td>Due Date</td><td>{proposal.CF_PRFDueDate}</td></tr>
              <tr><td>Is Fixed Due Date</td><td>{proposal.CF_PRFIsFixedDueDate ? 'Yes' : 'No'}</td></tr>
              <tr><td>Client Credit Ranking</td><td>{proposal.CF_PRFClientCreditRanking?.Value}</td></tr>
              <tr><td>Estimated Dollar Amount</td><td>{NumberFormatter.toDollar(proposal.CF_PRFEstAmtindollars)}</td></tr>
              <tr><td>PSE Experience</td><td>{proposal.CF_PRFPSEExperiance?.Value}</td></tr>
              <tr><td>Project Type</td><td>{proposal.CF_PRFProjectType}</td></tr>
              <tr><td>Billing Type</td><td>{proposal.CF_PRFBillingType?.Value}</td></tr>
              <tr><td>Engineering Level</td><td>{proposal.CF_PRFEngineeringLevel?.Value}</td></tr>
            </>) :
            (<>
              <tr><td>Dollar Amount</td><td>{NumberFormatter.toDollar(proposal.CF_ProposalDollarAmount)}</td></tr>
              <tr><td>Billing Type</td><td>{proposal.CF_ProposalBillingType?.Value}</td></tr>
              <tr><td>Expected Award Date</td><td>{proposal.CF_ProjectExpectedAwardDate}</td></tr>
              <tr><td>Probability Of Award</td><td>{proposal.CF_ProposalPropabilityOfAward}</td></tr>
              <tr><td>Terms</td><td>{proposal.CF_ProposalTerms}</td></tr>
              <tr><td>Outcome</td><td>{proposal.CF_ProposalOutcome || "TBD"}</td></tr>
            </>)}

          </tbody>
      </Table>

      {isNotPrf &&
        (<>
          <Label label="Est. Dollars and Hours by Dept." />

          <Table>
            <thead>
              <tr>
                <th>Dept</th>
                <th>Est Dollar</th>
                <th>Est Hours</th>
              </tr>
            </thead>
            <tbody>
              {Object
                .entries(estimations)
                .map(([dept, {dollars, hours}], i) => (
                  <tr key={i}>
                    <td>{dept}</td>
                    <td>{NumberFormatter.toDollar(dollars || '-')}</td>
                    <td>{hours || '-'}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </>)}

      <Label label="Contacts" />
      <Contacts contacts={proposal?.Contacts} />

      <Label label="Notes" />
      <Notes>{proposal.Notes || ""}</Notes>

      <Label label="Approved By (PRF)" />
      <ApproversTable approvers={prfApprovers} employees={employees} />

      {isNotPrf && 
        <>
          <Label label="Approved By (Proposal)" />
          <ApproversTable approvers={proposalApprovers} employees={employees} />
        </>}
      
      <Spacer /> 
      <HR />

      <span>Last Update: {(new Date(Date.parse(proposal?.LastModifiedDate))).toLocaleString()}</span>

    </Container>
  )
}

export default ViewHTML;
