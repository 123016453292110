import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import { useAjera } from "../../lib/ajera";
import { useToast } from "@chakra-ui/react";
import { TimeoutPromise } from "../../lib";

import { CircularProgress, Tooltip, Typography } from "@mui/material";
import { Fab, Fabs } from "../../components/Fab";


const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: "0px"
  },
  loading: {
    width: "100%",
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    flex: "0 0 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontWeight: "bold",
    color: "#777777",
    padding: "12px 0px 12px 16px",
    minHeight: "32px",
    // boxShadow: "1px 1px 2px #bbbbbb",
    // borderRadius: "8px",
    borderBottom: "1px solid #F0F0F0",
    borderLeft: "3px solid #0F6CBD",
    "&:hover": {
      cursor: "pointer",
      fontWeight: "bold",
      color: "#000000",
      backgroundColor: "#e7f1fb"
      // boxShadow: [
      //   "inset 1px 0 0 #dadce0",
      //   "inset -1px 0 0 #dadce0",
      //   "0 1px 2px 0 rgb(60 64 67 / 30%)",
      //   "0 1px 3px 1px rgb(60 64 67 / 15%)",
      // ],
    },
    "&:active": {
      // boxShadow: "1px 1px 2px #ffffff",
    },
  },
  subitem: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    // dispaly: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'space-between'

    position: 'relative',
    width: '100%'
  },
  badge: {
    position: 'absolute',
    right: '0px',
    padding: '0px 8px',
    borderRadius: '9999px',
    // backgroundColor: '#777777',
    // color: 'white',
    overflowX: 'hidden',
    fontSize: 12
  }
}));

export default function ListProposals({ state, updateState, navigate }) {
  console.log({state})
  const classes = useStyles();
  const ajera = useAjera();

  const [isLoading, setIsLoading] = useState(true);

  async function getProjects() {
    TimeoutPromise(ajera.listProjects())
      .then(x => {
        state.projects = x?.Projects || [];
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    if (state.projects.length !== 0) {
      setIsLoading(false);
      return;
    }

    getProjects();
  }, []);

  const Loading = () => (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );

  const List = () => (
    <div className={classes.container}>
      {state.projects
        .sort((a, b) => parseInt(b.ID) - parseInt(a.ID))
        .map((prop, i) => (
          <Tooltip key={i} title={prop.Description} placement="bottom" disableInteractive>
            <span
              className={classes.item}
              onClick={() => {
                navigate("view", { state: { ProjectKey: prop.ProjectKey }});
              }}
            >
              <span
                className={classes.subitem}
                alt={prop.Description}
              >
                <span>{prop.ID} - {prop.Description}</span>
                {/* <span className={classes.badge}>{prop.CF_Stage || (prop.ProjectKey % 2 === 0) ? "PRF" : "Proposal"}</span> */}
              </span>
            </span>
          </Tooltip>
        ))}
    </div>
  );

  if (isLoading) {
    return <Loading />;
  }
  
  return <List />;

}
