const moment = require('moment');


class DateFormatting {
  static toAjeraDateFormat = (date) => moment(date).format("yyyy-MM-DD hh:mm:ss.SSS [GMT]Z");
  static fromAjeraDateFormat = (dateString) => moment(dateString,  "yyyy-MM-DD hh:mm:ss.SSS [GMT]Z").toDate();
  static toViewDate = (date) => !date ? "Date Not Set" : date.toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
  static getDifferenceInDays = (date1, date2) => Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
}

const TimeoutPromise = async (promise, timeoutMessage='Request timed out', timeout=30000) => {
  const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error(timeoutMessage)), timeout))
  return await Promise.race([promise, timeoutPromise]);
};

class NumberFormatter {

  static toDollar(number) {
    return number?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    })
  }

  static toString(number) {
    return number?.toLocaleString()
  }

  static toPercent(number) {
    return number?.toLocaleString('en-US', {
      style: 'percent',
      minimumFractionDigits: 2
    })?.replace(/\.00%$/, '%')
  }
}

function calcNumofApprovers(billingType, dollarAmount) {
  
  if (!Number(dollarAmount)) return undefined;

  if (["T&M"].includes(billingType)) {
    // •	<$25k needs to be approved by someone other than the estimator
    // •	>$25k  and < $1Mk needs to be approved by 2 people other than the estimator
    // •	>$1M needs to be approved by 3 people other than the estimator

    if (dollarAmount < 25000) return 1;
    else if (dollarAmount >= 25000 && dollarAmount < 1000000) return 2;
    else if (dollarAmount >= 1000000) return 3;
    else return undefined;

  } else if (["T&M NTE", "Fixed Price"].includes(billingType)) {
    // •	<$25k needs to be approved by someone other than the estimator
    // •	>$25k  and < $500 needs to be approved by 2 people other than the estimator
    // •	>$500k  and < $1M needs to be approved by 3 people other than the estimator
    // •	>$1M needs to be approved by 4 people other than the estimator
    
    if (dollarAmount < 25000) return 1;
    else if (dollarAmount >= 25000 && dollarAmount < 500000) return 2;
    else if (dollarAmount >= 500000 && dollarAmount < 1000000) return 3;
    else if (dollarAmount >= 1000000) return 4;
    else return undefined;

  }
}

export {
  DateFormatting,
  TimeoutPromise,
  NumberFormatter,
  calcNumofApprovers
};