class API {

  baseURL = SERVER_URL;
  functionCode = SERVER_FUNCTION_CODE

  static toast = null;
  static setToast(callback) {
    API.toast = callback || (() => {})
  }

  async optionsCallback(options) {
    return options;
  }

  async request(path, payload, method) {
    console.log("request", {path, payload, method});

    const accessToken = await Office.auth.getAccessToken();
    const headers = { "X-Authorization": "Bearer " + accessToken };
    const requestOptions = { method, headers };

    if (method === "GET") {
      requestOptions.params = payload;
    } else {
      requestOptions.body = JSON.stringify(payload);
    }

  
    const url = new URL(this.baseURL + path);
    url.searchParams.append('code', this.functionCode);
    url.searchParams.append('cached', this.isCachedAPI);

    console.log({url: url.toString(), requestOptions, payload});

    try {

      const response = await fetch(url.toString(), requestOptions);
      var content = await response.text();

      try {
        content = JSON.parse(content);
      } catch (e) {
        content = { status: "error", message: content || "Unable to parse Response", e };
      }

      console.log({ok: response.ok, content})

      if (response.ok && (content?.status !== "error")) {

        return content;

      } else {

        const errorMessages = [ content?.message, content?.Content?.message, ...(content?.Errors ? (content?.Errors || []) : [content?.Message])]
          .map(v => typeof v === "string" ? v : typeof v === "object" ? v?.ErrorMessage : undefined)
          .filter(v => v);

        console.log({errorMessages});

        errorMessages?.map(err => 
          API.toast({
            title: err,
            status: "error",
            isClosable: true
          })
        )

        return Promise.reject(content);
      }

    } catch (error) {
      return Promise.reject(error);
    }
  }

  async get(path, payload) {
    return await this.request(path, payload, "GET");
  }

  async post(path, payload) {
    return await this.request(path, payload, "POST");
  }

  async put(path, payload) {
    return await this.request(path, payload, "PUT");
  }

  async patch(path, payload) {
    return await this.request(path, payload, "PATCH");
  }

  async delete(path, payload) {
    return await this.request(path, payload, "DELETE");
  }
}

class LocalStorage {
  static defaultTTL = 60 * 60 * 1000;  // 1-hour

  // Store data with an expiration time of 1 hour
  static setWithExpiry(key, value, ttl) {
    if (!ttl) ttl = LocalStorage.defaultTTL;
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }
  
  // Retrieve data from localStorage and check if it has expired
  static getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }
  
}

class CachedAPI extends API {

  constructor() {
    super()
    this.isCachedAPI = true;
  }

  async cachedRequest(path, payload, method) {
    const key = `${path}.${method}.${JSON.stringify(payload || {})}`
    console.log({key})
    const oldResponse = LocalStorage.getWithExpiry(key);
    if (oldResponse) {
      console.log({oldResponse})
      return oldResponse;
    }

    const response = await this.request(path, payload, method);
    LocalStorage.setWithExpiry(key, response)
    return response;
  }

}

class GraphAPI extends API {
  async me() {
    return await this.get("/graph/me");
  }

  async sendMail({ to, subject, text }) {
    return await this.post(`/send-mail`, { to, subject, text });
  }

  async sendViewMail(text, Stage, ProposalKey) {
    return await this.post(`/send-view-mail`, {text, Stage, ProposalKey});
  }
}

class ActionsAPI extends API {
  async newProposal() {
    return await this.get('/actions/start/proposal-uploaded');
  }

  async approveProposal() {
    return await this.get('/actions/start/proposal-approved');
  }
}

module.exports = {
  API,
  CachedAPI,
  GraphAPI,
  ActionsAPI,
  useAPI: () => new API(),
  useGraphAPI: () => new GraphAPI(),
  useActionsAPI: () => new ActionsAPI(),
};
